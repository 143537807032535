import { Link } from 'react-router-dom';
import { styled } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PropTypes from 'prop-types';
import { Button } from 'common/reusables/Button';

export const BackButton = ({ title, to, onClick, ...propsToSpread }) => {
  return onClick ? (
    <Button onClick={onClick} variant="text" {...propsToSpread}>
      <ChevronLeftIcon />
      <span>{title}</span>
    </Button>
  ) : (
    <StyledLink to={to} {...propsToSpread}>
      <ChevronLeftIcon />
      <span>{title}</span>
    </StyledLink>
  );
};

BackButton.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func
};

BackButton.defaultProps = {
  to: undefined,
  onClick: undefined
};

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  textDecoration: 'none',
  display: 'flex'
}));
