import { styled, Container, Grid } from '@mui/material';
import { Typography } from 'common/reusables/Typography';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FormSteppers } from 'app-admin/components/get-started/FormSteppers';
import { PersonalInformationForm } from 'app-admin/components/get-started/PersonalInformationForm';
import { AppDetailsForm } from 'app-admin/components/get-started/AppDetailsForm';
import { AppLogoUpload } from 'app-admin/components/get-started/AppLogoUpload';
import { Button } from 'common/reusables/Button';
import backgroundImage from 'assets/svgs/get-started-icons/background.svg';
import BimsLogo from 'assets/images/bims-logo.png';

const StyledWrapper = styled('div')(() => ({
  position: 'relative',
  background: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  minHeight: '100vh',

  '.get-started-button': {
    padding: '8px 41px'
  }
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(6),
  '.flex-container': {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
  },
  a: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 500
  },
  '.content': {
    width: 329
  },
  '.form-section': {
    width: 536,
    '.form': {
      background: theme.palette.background.default,
      padding: theme.spacing(4),
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(7),
      marginTop: theme.spacing(3)
    }
  }
}));

const StyledTopBar = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
  padding: '4px 0'
}));

const TopBar = () => {
  const navigate = useNavigate();

  return (
    <StyledTopBar className="top-bar">
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <img src={BimsLogo} alt="logo" width="66.6px" />
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Button variant="text" onClick={() => navigate('/login')}>
              Login
            </Button>
          </Grid>
        </Grid>
      </Container>
    </StyledTopBar>
  );
};

export const GetStartedPage = () => {
  const [activeStep, setActiveStep] = useState(0);

  const navigate = useNavigate();

  const onSuccess = () => {
    const appId = window.localStorage.getItem('app_id');
    navigate(`/applications/${appId}`);
  };

  return (
    <StyledWrapper>
      <TopBar />

      <StyledContainer>
        <div className="flex-container">
          <section className="content">
            <Typography variant="h1" fontSize={40} fontWeight={500} mb={1} mt={10}>
              Get Started
            </Typography>
            <Typography>
              Sign Up to access and sync your applications for better security.
            </Typography>

            <Typography mt={2}>
              Already have an account? <Link to="/login">Login</Link>
            </Typography>
          </section>

          <section className="form-section">
            <FormSteppers activeStep={activeStep} />
            <div className="form">
              {activeStep === 0 && (
                <PersonalInformationForm
                  onProceed={() => setActiveStep(1)}
                  isSubmitting={false}
                />
              )}
              {activeStep === 1 && (
                <AppDetailsForm onProceed={() => setActiveStep(2)} isSubmitting={false} />
              )}
              {activeStep === 2 && <AppLogoUpload onProceed={onSuccess} />}
            </div>
          </section>
        </div>
      </StyledContainer>
    </StyledWrapper>
  );
};
