import jwtDecode from 'jwt-decode';

export const setToken = (token) => {
  if (!token) return;
  localStorage.setItem('token', token);
};

export const getToken = () => {
  return localStorage.getItem('token');
};

export const getDecodedJwt = () => {
  try {
    const token = getToken();
    return jwtDecode(token);
  } catch (e) {
    return {};
  }
};

export const getUser = () => {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(user) : null;
};

export const removeToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

export const logOut = () => {
  removeToken();
  window.location.replace('/');
};

export const isAuthenticated = () => {
  try {
    const decodedToken = getDecodedJwt();

    const { exp } = decodedToken;
    const currentTime = Date.now() / 1000;

    return exp > currentTime;
  } catch (e) {
    return false;
  }
};
