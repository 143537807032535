import { Grid, IconButton, styled } from '@mui/material';
import { Typography } from 'common/reusables/Typography';
import { Visibility } from '@mui/icons-material';
import { ReactComponent as FileTypeIcon } from 'assets/svgs/fileType.svg';
import { ReactComponent as TrashIcon } from 'assets/svgs/redTrash.svg';

const StyledBox = styled(Grid)(() => ({
  a: {
    textDecoration: 'none'
  }
}));

export const DocumentBox = ({ label, url, onDelete }) => {
  return (
    <>
      <StyledBox container alignItems="center">
        <Grid item xs={3}>
          <FileTypeIcon />
        </Grid>
        <Grid item xs={6}>
          <Typography>{label}</Typography>
        </Grid>
        <Grid item container xs={3} alignItems="center">
          <Grid item xs={6} textAlign="right">
            <a href={url} target="_blank">
              <Visibility />
            </a>
          </Grid>

          <Grid item xs={6}>
            {onDelete && (
              <IconButton onClick={onDelete}>
                <TrashIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </StyledBox>
    </>
  );
};
