import { ApplicationForm } from 'app-applications/components/application-form/ApplicationForm';
import { BackButton } from 'common/reusables/BackButton';
import { styled } from '@mui/material';
import { DashboardLayout } from 'app-admin/components/dashboard-layout/DashboardLayout';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getApplicationDetails,
  updateApplication
} from 'app-applications/applications.service';
import { useQuery } from 'react-query';
import { Spinner } from 'common/reusables/Spinner';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';

export const EditApplicationPage = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();

  const appDetailsPage = `/applications/${applicationId}`;

  const { data, isFetching } = useQuery('getApplicationDetails', () =>
    getApplicationDetails(applicationId)
  );

  const { mutate, isLoading } = useMutation(updateApplication, {
    onSuccess(data) {
      toast.success(data.message || 'Saved');
    },
    onError(error) {
      toast.error(error);
    }
  });

  if (isFetching) {
    return <Spinner sx={{ mt: 5 }} />;
  }

  if (!data) {
    return <>An Error Occurred</>;
  }

  const defaultFormValues = {
    app: {
      name: data.name,
      description: data.description,
      callbackURL: data.callbackURL,
      clientId: data.id,
      pseDocumentUrl: data.pseDocument,
      logoUrl: data.logo
    },
    admin: {
      name: `${data.user.firstName} ${data.user.lastName}`,
      email: data.user.email
    }
  };

  const onSubmit = (payload) => {
    return mutate({ payload, applicationId });
  };

  return (
    <DashboardLayout>
      <StyledWrapper>
        <BackButton title="Back" to={appDetailsPage} />
        <ApplicationForm
          isSubmitting={isLoading}
          onSubmit={onSubmit}
          onCancel={() => navigate(`/applications/${applicationId}`)}
          submitButtonText="Update"
          initialValues={defaultFormValues}
          logoUrl={data.logo}
          pseDocumentUrl={data.pseDocument}
        />
      </StyledWrapper>
    </DashboardLayout>
  );
};

const StyledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(7),
  '& > :nth-of-type(1)': {
    marginRight: 173
  }
}));
