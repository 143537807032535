import { StyledStatCard } from './StatCard.styles';
import { CardContent } from 'common/reusables/Card';
import { Typography } from 'common/reusables/Typography';
import { SvgIcon } from 'common/reusables/SvgIcon';
import { formatNumberStandard } from 'utils/numberFormat';

export const StatCard = ({ title, count, icon = () => {}, ...restProps }) => {
  return (
    <StyledStatCard square elevation={0} {...restProps}>
      <CardContent>
        <Typography className="cardContent-title" variant="subtitle2">
          {title}
        </Typography>
        <Typography className="cardContent-count">
          {count ? formatNumberStandard(count) : '0'}
        </Typography>
        <SvgIcon component={icon} viewBox="0 0 40 40" className="cardContent-icon" />
      </CardContent>
    </StyledStatCard>
  );
};
