import { styled } from '@mui/material';
import { Card } from 'common/reusables/Card';

export const StyledStatCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.outline.main}`,

  '& .MuiCardContent-root': {
    padding: '16px 24px',
    display: 'grid',
    gridTemplateAreas: `
        "title icon"
        "count icon"
        `,

    '& .cardContent-title': {
      fontWeight: 400,
      color: theme.palette.text.dark,
      gridArea: 'title'
    },

    '& .cardContent-count': {
      gridArea: 'count',
      color: theme.palette.text.dark,
      fontSize: '1.5rem',
      fontWeight: 500
    },

    '& .cardContent-icon': {
      gridArea: 'icon',
      justifySelf: 'end',
      fontSize: '40px',
      color: 'transparent'
    }
  }
}));
