import { useState } from 'react';
import { Dialog, DialogContent } from 'common/reusables/Dialog';
import { Typography } from 'common/reusables/Typography';
import { Button } from 'common/reusables/Button';
import { styled } from '@mui/material';
import { pxToRem } from 'utils/pxToRem';
import cautionGif from 'assets/gifs/caution.gif';
import cancelGif from 'assets/gifs/cancel.gif';

const ConfirmationContent = ({ dialogImage, title, subtitle, renderAction }) => {
  return (
    <DialogContent>
      <img src={dialogImage} alt="alert gif" className="dialogContent__img" />
      <Typography component="h1" variant="h5" className="dialogContent__title">
        {title}
      </Typography>
      <Typography variant="subtitle1" className="dialogContent__subtitle">
        {subtitle}
      </Typography>
      {renderAction()}
    </DialogContent>
  );
};

export const RevokeAccessDialog = ({ open, onClose, mutate }) => {
  const [revokeSuccess, setRevokeSuccess] = useState(false);

  const handleRevoke = () => {
    mutate(null, {
      onSuccess: () => {
        setRevokeSuccess(true);
      }
    });
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      {revokeSuccess ? (
        <ConfirmationContent
          dialogImage={cancelGif}
          title="Access Revoked."
          subtitle="App has been revoked"
          renderAction={() => (
            <Button
              inverse="color"
              onClick={onClose}
              className="dialogContent__btn inverseBtn"
            >
              Close
            </Button>
          )}
        />
      ) : (
        <ConfirmationContent
          dialogImage={cautionGif}
          title="Are you sure?"
          subtitle={
            <>
              You are about to revoke <span>"DSLMS"</span>
            </>
          }
          renderAction={() => (
            <div className="dialogContent__btnGroup">
              <Button onClick={onClose} className="dialogContent__btn">
                Cancel
              </Button>
              <Button
                inverse="color"
                color="error"
                onClick={handleRevoke}
                className="dialogContent__btn"
              >
                Revoke Access
              </Button>
            </div>
          )}
        />
      )}
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '414px',
    width: '70%',
    padding: '40px 48px',
    borderRadius: 0
  },

  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: 0,

    '& .dialogContent': {
      '&__img': {
        maxWidth: '104px',
        height: 'auto',
        marginBottom: '16px'
      },
      '&__title': {
        fontSize: pxToRem(23),
        fontWeight: 500,
        marginBottom: 8,
        color: theme.palette.text.main
      },

      '&__subtitle': {
        fontSize: pxToRem(16),
        color: theme.palette.text.light,
        marginBottom: '16px'
      },

      '&__btnGroup': {
        display: 'flex',
        alignItems: 'center',
        columnGap: '8px',
        width: '100%'
      },

      '&__btn': {
        padding: '12px 26px',
        fontSize: pxToRem(16),
        fontWeight: 500,
        width: '100%',
        maxWidth: '155px',

        '&.MuiButton-containedError:hover': {
          backgroundColor: theme.palette.error[100],
          filter: 'brightness(0.97)'
        },

        '&.MuiButton-containedPrimary.inverseBtn:hover': {
          backgroundColor: theme.palette.primary.lighter,
          filter: 'brightness(0.97)'
        }
      }
    }
  }
}));
