import { DashboardLayout } from 'app-admin/components/dashboard-layout/DashboardLayout';
import { StyledAppContainer } from 'app-admin/pages/AdminDashboard.page';
import { ApplicationListNavigation } from 'app-applications/components/application-list/ApplicationListNavigation';
import { ReactComponent as TotalUsersIcon } from 'assets/svgs/uni.svg';
import { ReactComponent as TotalUsersActive } from 'assets/svgs/Group-active.svg';
import { ReactComponent as TotalUsersInactive } from 'assets/svgs/Group-inactive.svg';
import { StatCards } from 'app-admin/components/stat-cards/StatCards';
import { AppUsersList } from 'app-applications/components/application-details/AppUsersList';
import { AppDetailsHeader } from 'app-applications/components/application-list/AppDetailsHeader';
import { useState } from 'react';
import { ApplicationDetailsDrawer } from 'app-applications/components/application-details/ApplicationDetailsDrawer';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  getApplicationDetails,
  getApplicationUsers
} from 'app-applications/applications.service';
import { Spinner } from 'common/reusables/Spinner';
import { getUser } from 'utils/auth';
import { ApplicationStatusAlert } from 'app-applications/components/application-details/ApplicationStatusAlert';

export const ApplicationUsersPage = () => {
  const [showAlert, setShowAlert] = useState({
    pending: false,
    rejected: false
  });
  const [pagination, setPagination] = useState({ rowCount: 0, page: 0, pageSize: 10 });
  const { applicationId } = useParams();
  const user = getUser();

  const { data, isFetching } = useQuery(
    ['getApplicationDetails', applicationId],
    () => getApplicationDetails(applicationId),
    {
      onSuccess: (data) => {
        if (data.status === 'pending' && user.type_string === 'client') {
          setShowAlert({
            ...showAlert,
            pending: true
          });
        }

        if (data.status === 'rejected' && user.type_string === 'client') {
          setShowAlert({
            ...showAlert,
            rejected: true
          });
        }
      }
    }
  );

  const {
    data: { users } = { paginationMeta: {}, users: [] },
    isFetching: isFetchingUsers
  } = useQuery(
    [
      'getApplicationUsers',
      applicationId,
      {
        page: pagination.page + 1,
        per_page: pagination.pageSize,
        q: pagination.search,
        status: pagination?.status ?? ''
      }
    ],
    getApplicationUsers,
    {
      onSuccess: ({ paginationMeta }) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          rowCount: paginationMeta.total,
          page: paginationMeta.page,
          pageSize: paginationMeta.pageSize
        }));
      }
    }
  );

  const [showAppInfo, setShowAppInfo] = useState(false);

  if (isFetching) {
    return (
      <DashboardLayout>
        <Spinner sx={{ mt: 5 }} />
      </DashboardLayout>
    );
  }

  const cards = [
    { title: 'Total User', count: data?.usersCount, icon: TotalUsersIcon },
    {
      title: 'Total Active Users',
      count: data?.activeUsersCount,
      icon: TotalUsersActive
    },
    {
      title: 'Total Inactive Users',
      count: data?.inactiveUsersCount,
      icon: TotalUsersInactive
    }
  ];

  const page = user.type_string === 'client' ? 'home' : data?.name;
  const backPages = user.type_string === 'client' ? [] : [{ title: 'home', to: '/' }];

  console.log(pagination);

  return (
    <>
      <DashboardLayout>
        <StyledAppContainer>
          <ApplicationListNavigation page={page} backPages={backPages} />
          <ApplicationStatusAlert
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            rejectionReason={data?.rejectionReason}
          />

          <AppDetailsHeader
            name={data?.name}
            status={data?.status}
            imgUrl={data?.logo || ''}
            onViewInformation={() => setShowAppInfo(true)}
          />
          <StatCards cards={cards} />
          <section>
            <AppUsersList
              list={users}
              pagination={pagination}
              setPagination={setPagination}
              loading={isFetchingUsers}
            />
          </section>
        </StyledAppContainer>
      </DashboardLayout>
      {showAppInfo && (
        <ApplicationDetailsDrawer
          open
          onClose={() => setShowAppInfo(false)}
          data={data}
        />
      )}
    </>
  );
};
