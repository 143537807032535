export const breakpoints = {
  keys: ['xs', 'sm2', 'sm', 'md2', 'md', 'lg2', 'lg', 'xl2', 'xl'],
  values: {
    xs: 0,
    sm2: 320,
    sm: 600,
    md2: 768,
    md: 960,
    lg2: 1024,
    lg: 1280,
    xl2: 1440,
    xl: 1920
  }
};
