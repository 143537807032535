import { Table } from 'common/reusables/Table';
import { distanceToNow } from 'utils/date';
import { formatNumberStandard } from 'utils/numberFormat';
import { CellChip } from 'common/reusables/Table/CellChip';
import { Avatar } from 'common/reusables/Avatar';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getApplications } from 'app-applications/applications.service';
import { pxToRem } from 'utils/pxToRem';

const statusMapping = {
  approved: 'success',
  rejected: 'error'
};

export const formatAppName = ({ name, logo }) => {
  return (
    <StyledAppName>
      <Avatar src={logo} alt={name} />
      <span>{name}</span>
    </StyledAppName>
  );
};

const columns = [
  {
    headerName: 'App Name',
    field: 'name',
    flex: 0.8,
    renderCell: ({ row }) => {
      return formatAppName(row);
    }
  },
  {
    headerName: 'Client ID',
    field: 'id',
    valueGetter: ({ value }) => (value ? value : '--'),
    flex: 0.5,
    sortable: false
  },
  {
    headerName: 'No. of Users',
    field: 'noOfUsers',
    valueGetter: ({ value }) => (value ? formatNumberStandard(value) : 0),
    flex: 0.3
  },
  {
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => {
      return (
        <CellChip
          value={value?.toLowerCase()}
          type={statusMapping[value?.toLowerCase()]}
        />
      );
    },
    flex: 0.3
  },
  {
    headerName: 'Last Logon',
    field: 'lastLogon',
    valueGetter: ({ value }) => (value ? distanceToNow(value) : '--'),
    flex: 0.3,
    sortable: false
  }
];

export const ApplicationAllList = () => {
  const [pagination, setPagination] = useState({ rowCount: 0, page: 0, pageSize: 10 });

  const navigate = useNavigate();

  const { data: { apps } = { apps: [] }, isFetching } = useQuery(
    [
      'getApplications',
      {
        page: pagination.page + 1,
        per_page: pagination.pageSize,
        q: pagination.search,
        status: 'approved'
      }
    ],
    getApplications,
    {
      onSuccess: ({ paginationMeta }) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          rowCount: paginationMeta.total,
          page: paginationMeta.page,
          pageSize: paginationMeta.pageSize
        }));
      }
    }
  );

  const handleRowClick = (row) => {
    navigate(`/applications/${row.id}`);
  };

  return (
    <Table
      columns={columns}
      rows={apps}
      {...pagination}
      setPagination={setPagination}
      onRowClick={handleRowClick}
      noRowProps={{ title: 'No Apps..' }}
      loading={isFetching}
    />
  );
};

const StyledAppName = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: 11,

  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    fontSize: pxToRem(13)
  }
});
