import { Button } from 'common/reusables/Button';
import { useNavigate } from 'react-router-dom';

export const ClientDrawerAction = ({ closeDetailsDrawer }) => {
  const navigate = useNavigate();

  return (
    <>
      <Button inverse="color" className="inverseBtn" onClick={() => navigate('edit')}>
        Edit Info
      </Button>
      <Button onClick={closeDetailsDrawer}>Close</Button>
    </>
  );
};
