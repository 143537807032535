import { styled } from '@mui/material';
import authBackground from 'assets/svgs/auth-background.svg';
import tetFundLogo from 'assets/images/tet-fund-logo.png';
import Typography from 'common/reusables/Typography';
import PropTypes from 'prop-types';
import { pxToRem } from 'utils/pxToRem';
import BimsLogo from 'assets/images/bims-logo.png';

export const AuthPageLayout = ({ title, description, children }) => {
  return (
    <StyledWrapper>
      <div className="AuthPage-card">
        <div>
          <img src={BimsLogo} alt="logo" width="98px" />
        </div>
        {/*<Logo className="AuthPage-logo" />*/}
        <Typography variant="caption" textTransform="uppercase" letterSpacing={-0.1}>
          BENEFICIARY IDENTITY MANAGEMENT SERVICE
        </Typography>

        <div className="AuthPage-title-section">
          <Typography variant="h2" fontSize={23}>
            {title}
          </Typography>
          {description && <Typography variant="body1">{description}</Typography>}
        </div>
        {children}
        <div className="AuthPage-card-footer">
          <Typography>Powered By:</Typography>
          <img src={tetFundLogo} alt="TetFund" />
        </div>
      </div>
    </StyledWrapper>
  );
};

AuthPageLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  description: PropTypes.string
};

AuthPageLayout.defaultProps = {
  description: ''
};

const StyledWrapper = styled('div')(({ theme }) => ({
  height: '100vh',
  background: `url(${authBackground})`,
  position: 'relative',
  backgroundSize: 'cover',

  '.AuthPage-card': {
    background: theme.palette.background.default,
    width: 491,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderRadius: '0px 0px 8px 8px'
  },

  '.AuthPage-logo': {
    margin: 'auto',
    display: 'block'
  },

  '.AuthPage-title-section': {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: theme.palette.text.light,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
    h2: {
      color: theme.palette.text.main,
      marginBottom: theme.spacing(0.5)
    }
  },

  form: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },

  '.AuthPage-card-footer': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    '& > *': {
      display: 'block',
      margin: 'auto 0',
      fontSize: pxToRem(10)
    }
  }
}));
