import { SearchField } from 'common/reusables/SearchField/SearchField';
import { styled } from '@mui/material';

export const TableSearchBar = ({ onSearch, renderFilter }) => {
  return (
    <StyledTableSearchBar>
      <SearchField
        placeholder="Search App..."
        className="searchField"
        onChange={(event) => onSearch(event.target.value)}
      />
      {renderFilter && renderFilter()}
    </StyledTableSearchBar>
  );
};

const StyledTableSearchBar = styled('div')({
  display: 'flex',
  columnGap: '24px'
});
