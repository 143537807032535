import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { NewApplicationPage } from 'app-applications/pages/NewApplication.page';
import { AdminSignInRoute } from 'app-auth/pages/AdminSignIn.page';
import { DashboardPage } from 'app-admin/pages/AdminDashboard.page';
import { ApplicationUsersPage } from 'app-applications/pages/ApplicationUsers.page';
import { GetStartedPage } from 'app-admin/pages/GetStarted.page';
import { getUser } from 'utils/auth';
import { UserDashboardPage } from 'app-user/pages/UserDashboard.page';
import { EditApplicationPage } from 'app-applications/pages/EditApplication.page';

const AdminGuard = ({ component, ...props }) => {
  const token = window.localStorage.getItem('token');
  if (!token) return <Navigate to="/login" />;

  const Component = component;
  return <Component {...props} />;
};

const AdminDashboard = ({ ...props }) => {
  const user = getUser();

  if (user?.type_string === 'client') {
    return <Navigate to={`/applications/${user.clients[0].id}`} />;
  }
  if (user?.type_string === 'user') {
    return <UserDashboardPage {...props} />;
  }
  if (user?.type_string === 'admin') {
    return <DashboardPage {...props} />;
  }
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AdminGuard component={AdminDashboard} />} />
        <Route path="/login" element={<AdminSignInRoute />} />
        <Route
          path="/application"
          element={<AdminGuard component={NewApplicationPage} />}
        />
        <Route
          path="/applications/:applicationId"
          element={<AdminGuard component={ApplicationUsersPage} />}
        />
        <Route
          path="/applications/:applicationId/edit"
          element={<AdminGuard component={EditApplicationPage} />}
        />
        <Route path="/get-started" element={<GetStartedPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
