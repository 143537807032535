import { Api, handleApiError } from 'utils/Api';

export const getDashboardData = async () => {
  try {
    const { data } = await Api.get('/dashboard');
    return deserializers.getDashboardData(data.data);
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getInstitutions = async () => {
  try {
    const { data } = await Api.get('/institutions');
    return data.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

const deserializers = {
  getDashboardData(data) {
    return {
      stats: {
        totalApps: data.clients_count,
        totalUsers: data.users_count,
        totalOnlineUsers: data.access_tokens_count
      },
      apps:
        data?.clients?.map((client) => {
          return {
            id: client.id,
            userId: client.user_id,
            description: client.description,
            logo: client.logo,
            name: client.name,
            provider: client.provider,
            redirect: client.redirect,
            personalAccessClient: client.personal_access_client,
            passwordClient: client.password_client,
            revoked: client.revoked,
            createdAt: client.created_at,
            updatedAt: client.updated_at
          };
        }) ?? []
    };
  }
};
