import { ReactComponent as SearchIcon } from 'assets/svgs/search-1.svg';
import { InputAdornment } from 'common/reusables/form-components/Input';
import { SvgIcon } from 'common/reusables/SvgIcon';
import { pxToRem } from 'utils/pxToRem';
import { StyledSearchField } from './SearchField.styles';

export const SearchField = ({ placeholder, maxWidth = 320, ...restProps }) => {
  return (
    <StyledSearchField
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon
              component={SearchIcon}
              viewBox="0 0 16 16"
              sx={{ color: 'transparent', fontSize: pxToRem(16) }}
            />
          </InputAdornment>
        )
      }}
      sx={{ maxWidth: pxToRem(maxWidth) }}
      {...restProps}
    />
  );
};
