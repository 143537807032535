import { styled } from '@mui/material';
import { Badge } from 'common/reusables/Badge';
import { pxToRem } from 'utils/pxToRem';

export const StyledAvatarBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.primary[200],
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""'
    }
  },

  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.primary[300],
    fontSize: pxToRem(13)
  }
}));
