import { useState } from 'react';
import { CopyToClipboard as RCTC } from 'react-copy-to-clipboard';
import { IconButton } from 'common/reusables/Button';
import { SvgIcon } from 'common/reusables/SvgIcon';
import { ReactComponent as CopyIcon } from 'assets/svgs/copy.svg';
import { styled } from '@mui/material';
import { Tooltip, tooltipClasses } from 'common/reusables/Tooltip';
import { Typography } from 'common/reusables/Typography';

export const CopyToClipboard = ({ text, onCopy, fullWidth }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (text, result) => {
    if (result) setOpen(true);
    if (onCopy) onCopy();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <RCTC text={text} onCopy={handleOpen}>
      <StyledTooltip
        title={'Copied to clipboard'}
        leaveDelay={500}
        placement="top-end"
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
      >
        <StyledTooltipContent>
          <Typography>{text}</Typography>
          <IconButton>
            <SvgIcon component={CopyIcon} viewBox="0 0 18 20" />
          </IconButton>
        </StyledTooltipContent>
      </StyledTooltip>
    </RCTC>
  );
};

CopyToClipboard.defaultProps = {
  text: '--'
};

const StyledTooltipContent = styled('div')(({ theme, fullWidth }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  columnGap: 5,
  justifyContent: 'flex-end',

  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',

    '& .MuiSvgIcon-root path': {
      fill: `${theme.palette.primary.main}`
    }
  },

  '& .MuiIconButton-root': {
    ...(fullWidth && { marginLeft: 'auto' }),
    padding: '4px'
  },

  '& .MuiSvgIcon-root': {
    fontSize: theme.typography.pxToRem(18)
  }
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow placement="top-end" classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 8,
    fontSize: theme.typography.pxToRem(16),
    backgroundColor: theme.palette.common.white,
    color: '#393A4A',
    boxShadow: '0px 3px 16px rgba(0, 0, 0, 0.1)',
    fontWeight: 400
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white
  }
}));
