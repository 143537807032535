import { styled } from '@mui/material';
import AlarmClockImg from 'assets/images/alarm_clock.png';
import { getUser } from 'utils/auth';
import { formatDate } from 'utils/date';
import { useQuery } from 'react-query';
import { getDashboardData } from 'app-user/user.service';

export const DashboardBanner = () => {
  const user = getUser();

  const { data: dashboardData } = useQuery(['getDashboardData'], getDashboardData);

  return (
    <StyledBanner>
      <div className="title">
        <p className="title__name">Welcome back {user?.first_name},</p>
        <p className="title__count">
          Total Applications: <span>{dashboardData?.totalApps}</span>
        </p>
      </div>
      <div className="calender">
        <div className="calender__wrapper">
          <p className="calender__date">
            {formatDate(new Date(), 'EEEE, MMMM dd, yyyy')}
          </p>
          <p className="calender__time">{formatDate(new Date(), 'hh:mmaaa')}</p>
        </div>
        <div className="calender__img-wrapper">
          <img src={AlarmClockImg} alt="alarm clock" className="calender__img" />
        </div>
      </div>
    </StyledBanner>
  );
};

const StyledBanner = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  padding: '24px',
  position: 'relative',
  rowGap: '24px',
  marginBottom: '32px',

  '& p': {
    margin: 0
  },

  '& .title': {
    '&__name': {
      fontSize: theme.typography.pxToRem(32),
      color: theme.palette.common.white
    },

    '&__count': {
      color: theme.palette.common.white,

      '& span': {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(20)
      }
    }
  },

  '& .calender': {
    '&__wrapper': {
      color: theme.palette.common.white,
      marginRight: '100px'
    },

    '&__date': {
      fontSize: theme.typography.pxToRem(20)
    },

    '&__time': {
      textAlign: 'right'
    },

    '&__img-wrapper': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      display: 'flex'
    },

    '&__img': {
      alignSelf: 'flex-end'
    }
  }
}));
