import { styled, Chip, InputBase, Box, InputLabel, FormHelperText } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useEffect, useRef, useState } from 'react';

const StyledInputBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey[400]}`,
  '&.error': {
    borderColor: theme.palette.error.main
  },
  '.MuiChip-root': {
    marginRight: theme.spacing(0.5)
  },
  '.MuiInputLabel-root': {
    marginBottom: theme.spacing(1)
  }
}));

export const TagInput = ({
  value = '',
  delimiter = ',',
  inputProps = {},
  label = '',
  helperText,
  onChange,
  name,
  error = false,
  ...rest
}) => {
  const [tags, setTags] = useState(() => {
    return value ? value.split(delimiter) : [];
  });
  const [inputValue, setInputValue] = useState('');
  const [eventMock, setEventMock] = useState({ target: { value: null } });
  const inputRef = useRef();

  useEffect(() => {
    if (inputValue && inputValue.includes(delimiter)) {
      setTags((previousValue) => [
        ...previousValue,
        ...inputValue.split(delimiter).filter((v) => !!v)
      ]);
      setInputValue('');
    }
  }, [inputValue, setTags, setInputValue]);

  const onBoxClick = () => {
    inputRef?.current?.focus();
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setEventMock(e);
    e.target.value = [...tags, e.target.value].join(delimiter);
    onChange(e);
  };

  const handleRemoveTag = (tagIndex) => {
    const tagsCopy = [...tags];
    tagsCopy.splice(tagIndex, 1);
    const eventMockCopy = { ...eventMock };
    eventMockCopy.target.value = tagsCopy.join(delimiter);

    onChange(eventMockCopy);
    setTags(tagsCopy);
  };

  return (
    <>
      <StyledInputBox onClick={onBoxClick} {...rest} className={error ? 'error' : ''}>
        <InputLabel>{label}</InputLabel>
        {tags.map((tag, index) => (
          <Chip key={uuid()} label={tag} onDelete={() => handleRemoveTag(index)} />
        ))}
        <InputBase
          inputRef={inputRef}
          value={inputValue}
          name={name}
          onChange={handleInputChange}
          {...inputProps}
        />
      </StyledInputBox>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
};
