import { ApplicationForm } from 'app-applications/components/application-form/ApplicationForm';
import { BackButton } from 'common/reusables/BackButton';
import { styled } from '@mui/material';
import { DashboardLayout } from 'app-admin/components/dashboard-layout/DashboardLayout';
import { useNavigate } from 'react-router-dom';

export const NewApplicationPage = () => {
  const navigate = useNavigate();
  const onSubmit = () => {
    navigate('/');
  };

  return (
    <DashboardLayout>
      <StyledWrapper>
        <BackButton title="Back to Home" to="/" />
        <ApplicationForm
          onSubmit={onSubmit}
          onCancel={onSubmit}
          submitButtonText="Add App"
        />
      </StyledWrapper>
    </DashboardLayout>
  );
};

const StyledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(7),
  '& > :nth-of-type(1)': {
    marginRight: 173
  }
}));
