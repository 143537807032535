import { styled } from '@mui/material';
import { Chip } from 'common/reusables/Chip';
import { pxToRem } from 'utils/pxToRem';

export const StyledChip = styled(Chip)(({ theme, type }) => {
  const typeMapping = {
    success: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary[100]
    },
    error: {
      color: theme.palette.error.main,
      backgroundColor: theme.palette.error[100]
    }
  };

  return {
    fontSize: pxToRem(12),
    borderRadius: '4px',
    height: '20px',
    ...typeMapping[type],

    '& .MuiChip-label': {
      textTransform: 'capitalize',
      paddingLeft: '6px',
      paddingRight: '6px'
    }
  };
});
