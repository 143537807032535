import { DashboardLayout } from 'app-admin/components/dashboard-layout/DashboardLayout';
import { ApplicationListNavigation } from 'app-applications/components/application-list/ApplicationListNavigation';
import { DashboardApplicationList } from 'app-user/components/DashboardApplicationList';
import { DashboardBanner } from 'app-user/components/DashboardBanner';

export const UserDashboardPage = () => {
  return (
    <DashboardLayout>
      <ApplicationListNavigation page="home" />
      <DashboardBanner />
      <DashboardApplicationList />
    </DashboardLayout>
  );
};
