import { Grid, styled } from '@mui/material';
import { Drawer } from 'common/reusables/Drawer';
import Typography from 'common/reusables/Typography';
import { pxToRem } from 'utils/pxToRem';
import { CellChip } from 'common/reusables/Table/CellChip';
import { getUser } from 'utils/auth';
import { Avatar } from 'common/reusables/Avatar';
import { CopyToClipboard } from 'common/reusables/CopyToClipboard';
import { Circle } from '@mui/icons-material';
import { List, ListItem, ListItemIcon, ListItemText } from 'common/reusables/List';
import { Link } from 'common/reusables/Link';
import { AdminDrawerAction } from 'app-applications/components/application-details/AdminDrawerAction';
import { ClientDrawerAction } from 'app-applications/components/application-details/ClientDrawerAction';

const statusMapping = {
  approved: 'success',
  rejected: 'error'
};

export const ApplicationDetailsDrawer = ({ open, onClose, data = {} }) => {
  const user = getUser();
  const redirectUrls = data?.callbackURL.split(',') || [];

  return (
    <Drawer title="App Information" open={open} onClose={onClose}>
      <StyledDrawerContent>
        <Grid container className="header">
          <Grid item>
            <Avatar src={data.logo} />
          </Grid>
          <Grid item xs={10} className="label">
            <Typography variant="h3">{data.name}</Typography>
          </Grid>
        </Grid>
        <Typography className="value">
          {data.description || <i>No description</i>}
        </Typography>

        <Typography variant="h4">More Information</Typography>
        <Grid container rowSpacing={3} alignItems="center">
          <Grid item xs={6} className="label">
            Status:
          </Grid>
          <Grid item xs={6} textAlign="right" className="value">
            <CellChip
              type={statusMapping[data.status?.toLowerCase()]}
              value={data.status}
            />
          </Grid>
          <Grid item xs={6} className="label">
            Client ID:
          </Grid>
          <Grid item xs={6} textAlign="right" className="value">
            <CopyToClipboard text={data.id} />
          </Grid>
          <Grid item xs={12} className="label">
            Client Token:
          </Grid>
          <Grid item xs={12} className="value" sx={{ mt: -2 }}>
            <CopyToClipboard text={data.clientToken} fullWidth />
          </Grid>
          <Grid item xs={12} className="label">
            Redirect URL:
          </Grid>
          <Grid item xs={12} className="value" sx={{ mt: -2 }}>
            <StyledList>
              {redirectUrls
                .filter((v) => !!v)
                .map((url, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <Circle sx={{ fontSize: 8, color: '#559C77' }} />
                    </ListItemIcon>
                    <ListItemText primary={url} />
                  </ListItem>
                ))}
            </StyledList>
          </Grid>
        </Grid>

        <Typography variant="h4">Admin Information</Typography>
        <Grid container rowSpacing={2}>
          <Grid item xs={6} className="label">
            Full Name
          </Grid>
          <Grid item xs={6} className="value">
            {data.adminName || '--'}
          </Grid>

          <Grid item xs={6} className="label">
            Email Address
          </Grid>
          <Grid item xs={6} className="value">
            {data.adminEmail || '--'}
          </Grid>
        </Grid>
        <Typography variant="h4">PSE Approval Document</Typography>
        <Grid container>
          <Grid item xs={12} className="value">
            {!!data.pseDocument ? (
              <Link href={data.pseDocument} target="_blank" rel="noopener">
                {data.pseDocument}
              </Link>
            ) : (
              '--'
            )}
          </Grid>
        </Grid>
      </StyledDrawerContent>
      <StyledDrawerAction>
        {user.type_string === 'admin' && (
          <AdminDrawerAction
            closeDetailsDrawer={onClose}
            id={data.id}
            status={data.status}
          />
        )}
        {user.type_string === 'client' && (
          <ClientDrawerAction closeDetailsDrawer={onClose} id={data.id} />
        )}
      </StyledDrawerAction>
    </Drawer>
  );
};

const StyledDrawerContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: theme.spacing(4),
  wordWrap: 'break-word',
  maxHeight: 'calc(100vh - 170px)',
  overflowY: 'auto',

  '.header': {
    marginBottom: 12
  },
  '.MuiTypography-h3': {
    fontSize: pxToRem(24),
    color: theme.palette.text.black
  },
  '.MuiTypography-h4': {
    fontSize: pxToRem(16),
    fontWeight: 700,
    color: theme.palette.text.main,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3)
  },
  '& .MuiAvatar-root': {
    marginRight: 11,
    width: 26,
    height: 26
  },
  '.value': {},
  '.label': {
    color: theme.palette.text.light
  }
}));

export const StyledDrawerAction = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: '16px',
  borderTop: `1px solid ${theme.palette.outline.main}`,
  position: 'absolute',
  bottom: 0,
  padding: '24px',
  backgroundColor: theme.palette.common.white,

  '& .MuiButton-root': {
    padding: '0.65em 2.5em',
    fontSize: pxToRem(16),
    fontWeight: 500,
    flexBasis: '144px',

    '&.MuiButton-containedError.inverseBtn:hover': {
      backgroundColor: theme.palette.error[100],
      filter: 'brightness(0.97)'
    },

    '&.MuiButton-containedPrimary.inverseBtn:hover': {
      backgroundColor: theme.palette.primary.lighter,
      filter: 'brightness(0.97)'
    }
  }
}));

const StyledList = styled(List)(({ theme }) => ({
  '& .MuiListItem-root': {
    alignItems: 'flex-start',
    paddingLeft: 0,

    '& .MuiListItemIcon-root': {
      minWidth: 16
    },

    '& .MuiListItemText-root': {
      marginTop: -10,

      '& .MuiTypography-root': {
        cursor: 'default',
        userSelect: 'all'
      },

      '& .MuiTypography-root:hover': {
        fontWeight: 500,
        color: theme.palette.secondary.main
      }
    }
  }
}));
