import { useState } from 'react';
import { Drawer } from 'common/reusables/Drawer';
import { InputLabel } from 'common/reusables/form-components/Input';
import { TextField } from 'common/reusables/form-components/TextField';
import { Button, LoadingButton } from 'common/reusables/Button';
import { styled } from '@mui/material';
import { pxToRem } from 'utils/pxToRem';
import { StyledDrawerAction } from 'app-applications/components/application-details/ApplicationDetailsDrawer';
import cancelGif from 'assets/gifs/cancel.gif';
import { ApplicationDialog } from 'app-applications/components/application-details/ApplicationDialog';
import { useMutation, useQueryClient } from 'react-query';
import { updateApplicationStatus } from 'app-applications/applications.service';
import { toast } from 'react-hot-toast';

export const ApplicationRejectionDrawer = ({ open, closeDrawer, id }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [reason, setReason] = useState('');

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (payload) => updateApplicationStatus({ id, payload }),
    {
      onSuccess: () => {
        setOpenDialog(true);
      },
      onError: (error) => {
        toast.error(error);
      }
    }
  );

  const handleReject = () => {
    const payload = {
      status: 'rejected',
      rejectionReason: reason
    };
    mutate(payload);
  };

  const handleCloseDialog = () => {
    closeDrawer();
    queryClient.invalidateQueries('getApplicationDetails');
  };

  return (
    <>
      <Drawer title="Reason for Rejection" open={open} onClose={closeDrawer}>
        <StyledDrawerContent>
          <InputLabel>Enter your reason for Rejection</InputLabel>
          <TextField
            multiline
            fullWidth
            rows={10}
            placeholder="Type here"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </StyledDrawerContent>
        <StyledDrawerAction>
          <Button inverse="color" className="inverseBtn" onClick={closeDrawer}>
            Cancel
          </Button>
          <LoadingButton
            color="error"
            onClick={handleReject}
            disabled={reason.length <= 0 || isLoading}
            loadingPosition="end"
            endIcon={<></>}
            variant="contained"
            loading={isLoading}
          >
            Reject
          </LoadingButton>
        </StyledDrawerAction>
      </Drawer>

      <ApplicationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        dialogImage={cancelGif}
        title="Request Rejected"
        subtitle="Request has been rejected"
        buttonLabel="Close"
      />
    </>
  );
};

const StyledDrawerContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: theme.spacing(4),

  '& .MuiInputLabel-root': {
    fontSize: pxToRem(14),
    marginBottom: '8px'
  },

  '& .MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline:hover':
      {
        borderColor: theme.palette.text.main,
        borderWidth: '1px'
      }
  }
}));
