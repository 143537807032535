import { styled, Typography } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { ReactComponent as OneActiveIcon } from 'assets/svgs/get-started-icons/1-active.svg';
import { ReactComponent as CheckTickIcon } from 'assets/svgs/get-started-icons/check-tick.svg';
import { ReactComponent as TwoDefaultIcon } from 'assets/svgs/get-started-icons/2-default.svg';
import { ReactComponent as TwoActiveIcon } from 'assets/svgs/get-started-icons/2-active.svg';
import { ReactComponent as ThreeDefaultIcon } from 'assets/svgs/get-started-icons/3-default.svg';
import { ReactComponent as ThreeActiveIcon } from 'assets/svgs/get-started-icons/3-active.svg';

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#A7A9BC',
    borderRadius: 1
  }
}));

const mySteps = [
  {
    label: 'Personal Info',
    defaultIcon: OneActiveIcon,
    activeIcon: OneActiveIcon,
    completedIcon: CheckTickIcon
  },
  {
    label: 'App Details',
    defaultIcon: TwoDefaultIcon,
    activeIcon: TwoActiveIcon,
    completedIcon: CheckTickIcon
  },
  {
    label: 'Uploads',
    defaultIcon: ThreeDefaultIcon,
    activeIcon: ThreeActiveIcon,
    completedIcon: CheckTickIcon
  }
];

export const FormSteppers = ({ activeStep }) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={1}
      connector={<StyledConnector />}
      sx={{ width: 536 }}
    >
      {mySteps.map((step, index) => {
        const isActive = activeStep === index;
        let Icon = step.defaultIcon;

        if (isActive) Icon = step.activeIcon;
        if (index < activeStep) Icon = step.completedIcon;

        return (
          <Step key={step.label}>
            <StepLabel StepIconComponent={Icon} sx={{ color: 'blue' }}>
              <Typography color={isActive ? 'primary' : 'default'}>
                {step.label}
              </Typography>
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
