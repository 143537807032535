import { Tabs } from 'common/reusables/Tabs';
import { Tab } from 'common/reusables/Tab';
import { styled } from '@mui/material';
import { pxToRem } from 'utils/pxToRem';

export const StyledTableTabs = styled(Tabs)({
  width: 'fit-content',
  minHeight: 'unset',
  marginBottom: '16px',

  '& .MuiTabs-indicator': {
    display: 'none'
  }
});

export const StyledTableTab = styled(Tab)(({ theme }) => ({
  textTransform: 'capitalize',
  fontWeight: 400,
  borderRadius: '2px',
  minHeight: 'unset',

  '& .request-count': {
    display: 'inline-block',
    marginLeft: '4px',
    color: theme.palette.primary.main
  },

  '& .MuiSvgIcon-root': {
    color: 'transparent',
    marginRight: '8px',
    fontSize: pxToRem(16),
    '& path': {
      stroke: `${theme.palette.text.light} !important`
    }
  },

  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,

    '& .request-count': {
      color: theme.palette.common.white
    },

    '& .MuiSvgIcon-root path': {
      stroke: `${theme.palette.common.white} !important`
    }
  }
}));
