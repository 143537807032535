import { Alert } from 'common/reusables/Alert';
import { ReactComponent as WarningIcon } from 'assets/svgs/caution.svg';
import { ReactComponent as ErrorIcon } from 'assets/svgs/error.svg';
import { SvgIcon } from 'common/reusables/SvgIcon';
import { styled } from '@mui/material';
import { pxToRem } from 'utils/pxToRem';

export const AlertBanner = ({ content, ...restProps }) => {
  return (
	  <StyledAlert
		  iconMapping={{
			error: <SvgIcon component={ErrorIcon} />,
			warning: <SvgIcon component={WarningIcon} />
		  }}
		  {...restProps}
	  >
		{content}
	  </StyledAlert>
  );
};

const StyledAlert = styled(Alert)({
  minHeight: '59px',
  marginBottom: '24px',

  '& .MuiAlert-icon': {
	alignItems: 'center',

	'& .MuiSvgIcon-root': {
	  fontSize: pxToRem(18)
	}
  },

  '& .MuiAlert-action': {
	alignItems: 'center'
  },

  '& .MuiAlert-message': {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',
	padding: '2px 0',

	'& .cta': {
	  textDecoration: 'underline',
	  cursor: 'pointer'
	},

	'& .MuiButton-root': {
	  padding: '8px 16px'
	}
  }
});
