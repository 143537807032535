import { Api, handleApiError } from 'utils/Api';

export const loginAdmin = async ({ email, password }) => {
  try {
    const { data } = await Api.post('/auth/login', { email, password });
    return deserializers.loginAdmin(data.data);
  } catch (error) {
    throw handleApiError(error);
  }
};

export const registerUser = async (payload) => {
  try {
    const { data } = await Api.post('/auth/register', serializers.registerUser(payload));
    // window.localStorage.localStorage('user', JSON.parse(data.user));
    return deserializers.registerUser(data.data);
  } catch (error) {
    throw handleApiError(error);
  }
};

const serializers = {
  registerUser(payload) {
    return {
      first_name: payload.firstName,
      last_name: payload.lastName,
      phone: payload.phone,
      email: payload.email,
      phone: payload.phone,
      password: payload.password,
      password_confirmation: payload.password
    };
  }
};

const deserializers = {
  loginAdmin(payload) {
    return {
      token: payload.token,
      user: payload.user
    };
  },
  registerUser(payload) {
    console.log('USER', payload.user);
    return {
      token: payload.token,
      user: payload.user
    };
  }
};
