import { StatCard } from 'common/reusables/StatCard';
import { styled } from '@mui/material';
import { Stack } from 'common/reusables/Stack';

export const StatCards = ({ cards }) => {
  return (
    <StyledStacks component="section" direction="row">
      {cards.map((card, index) => {
        return (
          <StatCard key={index} title={card.title} count={card.count} icon={card.icon} />
        );
      })}
    </StyledStacks>
  );
};

const StyledStacks = styled(Stack)(({ theme }) => ({
  marginTop: '6px',
  justifyContent: 'space-between',
  columnGap: '16px',
  rowGap: '16px',
  marginBottom: '24px',
  flexWrap: 'wrap',

  '& .MuiCard-root': {
    flex: '1 1 auto',
    minWidth: '175px',
    maxWidth: '360px'
  }
}));
