export const GlobalStyles = (theme) => {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: '#F8FBFA'
        }
      }
    }
  };
};
