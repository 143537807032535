import { FormLabel, styled, Typography, Grid } from '@mui/material';
import { TextField } from 'common/reusables/form-components/TextField';
import { Button, IconButton, LoadingButton } from 'common/reusables/Button';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { ReactComponent as AppLogoUploadIcon } from 'assets/svgs/app-logo-upload.svg';
import { TagInput } from 'common/reusables/TagsInput';
import { UploadBox } from 'app-admin/components/get-started/AppLogoUpload';
import { DocumentBox } from 'common/reusables/DocumentBox';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';

const AppLogoUpload = ({ value, setFile }) => {
  const fileUploadRef = useRef();
  const [uploadedFileUrl, setUploadedFileUrl] = useState(value);

  const handleFileChange = (e) => {
    const fileReader = new FileReader();
    const selectedFile = e.target.files[0];

    fileReader.readAsDataURL(selectedFile);

    fileReader.onloadend = (event) => {
      setUploadedFileUrl(event.target.result);
      setFile(selectedFile);

      console.log(typeof selectedFile);
    };

    fileReader.onerror = () => {
      toast.error('An error occurred while loading the image. Please try again');
    };
  };

  const handleInitiateUpload = async () => {
    fileUploadRef?.current?.click();
  };

  const handleLoadedData = (e) => {
    console.log(e?.target?.value);
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={3}>
          {uploadedFileUrl ? (
            <img
              src={uploadedFileUrl}
              alt="Uploaded App Logo"
              className="uploaded-logo"
            />
          ) : (
            <IconButton disableRipple disableFocusRipple onClick={handleInitiateUpload}>
              <AppLogoUploadIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={8.5}>
          <Typography variant="body2">
            Tap on icon to upload app logo and the <br />
            <strong>
              Recommendation Image size is 24x 24cm, must be in PNG format not exceed
              500kb
            </strong>
          </Typography>
          {uploadedFileUrl && (
            <Button variant="text" onClick={handleInitiateUpload}>
              Change Logo
            </Button>
          )}
        </Grid>
      </Grid>

      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileUploadRef}
        accept=".png"
        onChange={handleFileChange}
        onLoadedData={handleLoadedData}
      />
    </>
  );
};

const initialFormValues = {
  app: {
    name: '',
    description: '',
    clientId: '',
    clientToken: '',
    callbackURL: '',
    pseDocumentUrl: '',
    logoUrl: ''
  },
  admin: {
    name: '',
    email: ''
  }
};

const validationSchema = Yup.object({
  app: Yup.object({
    name: Yup.string().required('Required').nullable(),
    description: Yup.string().required('Required').nullable(),
    // clientId: Yup.string().required('Required'),
    // clientToken: Yup.string().required('Required'),
    callbackURL: Yup.string().url('Enter a valid URL').required('Required').nullable()
  }).required(),
  admin: Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Enter a valid email address').required('Required')
  })
});

export const ApplicationForm = ({
  onSubmit,
  onCancel,
  initialValues = initialFormValues,
  submitButtonText,
  displayTnC = true,
  isSubmitting
}) => {
  const {
    handleChange,
    values: formValues,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => onSubmit(formValues),
    validateOnBlur: true,
    enableReinitialize: true
  });

  console.log(formValues);

  return (
    <StyledForm onSubmit={handleSubmit}>
      <section>
        <FormLabel>App Information</FormLabel>

        <TextField
          fullWidth
          label="App Name"
          name="app.name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={formValues.app.name}
          error={touched.app?.name && !!errors.app?.name}
          helperText={touched.app?.name && errors.app?.name}
        />
        <TextField
          fullWidth
          multiline
          minRows={3}
          label="App Description"
          name="app.description"
          onChange={handleChange}
          onBlur={handleBlur}
          value={formValues.app.description}
          error={touched.app?.description && !!errors.app?.description}
          helperText={touched.app?.description && errors.app?.description}
        />

        <TextField
          fullWidth
          disabled
          // label="APP ID"
          name="app.clientId"
          value={formValues.app.clientId}
        />

        {/* <TextField
          fullWidth
          label="Client Token"
          name="app.clientToken"
          onChange={handleChange}
          onBlur={handleBlur}
          value={formValues.app.clientToken}
          error={touched.app?.clientToken && !!errors.app?.clientToken}
          helperText={touched.app?.clientToken && errors.app?.clientToken}
        /> */}

        <TagInput
          fullWidth
          label="Callback URL"
          name="app.callbackURL"
          onChange={handleChange}
          onBlur={handleBlur}
          value={formValues.app.callbackURL}
          error={touched.app?.callbackURL && !!errors.app?.callbackURL}
          helperText={touched.app?.callbackURL && errors.app?.callbackURL}
        />
      </section>

      <section>
        <FormLabel>Admin Information</FormLabel>

        <TextField
          disabled
          fullWidth
          label="Full Name"
          name="admin.name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={formValues.admin.name}
          error={touched.admin?.name && !!errors.admin?.name}
          helperText={touched.admin?.name && errors.admin?.name}
        />

        <TextField
          disabled
          fullWidth
          label="Email Address"
          name="admin.email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={formValues.admin.email}
          error={touched.admin?.email && !!errors.admin?.email}
          helperText={touched.admin?.email && errors.admin?.email}
        />
      </section>

      <section>
        <FormLabel>App Logo</FormLabel>
        <AppLogoUpload
          value={formValues.app.logoUrl}
          setFile={(file) => setFieldValue('app.logoUrl', file)}
        />
      </section>

      <section>
        <FormLabel>PBS Approval Document</FormLabel>
        <UploadBox
          instruction="Format (csv). Maximum of 5MB"
          onCancel={() => {}}
          existingFile={formValues.app.pseDocumentUrl}
          onUpload={(file) => setFieldValue('app.pseDocumentUrl', file)}
          renderExistingFile={() => {
            return (
              <DocumentBox
                label="PSE Document"
                url={formValues.app.pseDocumentUrl}
                onDelete={() => setFieldValue('app.pseDocumentUrl', null)}
              />
            );
          }}
        />
        {/* <FileUploadBox instruction="Format (csv). Maximum of 5MB" /> */}
      </section>

      {displayTnC && (
        <Typography variant="body2" className="footer">
          By clicking <strong>“Add App”</strong>, You agree to the{' '}
          <Link to="/terms-and-conditions">Terms and Conditions</Link> of using this
          platform for the purpose which it serves.
        </Typography>
      )}
      <div className="buttons-group">
        <Button variant="contained" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={isSubmitting}
        >
          {submitButtonText}
        </LoadingButton>
      </div>
    </StyledForm>
  );
};

const StyledForm = styled('form')(({ theme }) => ({
  width: 536,
  padding: '48px 32px',
  paddingTop: theme.spacing(2),
  background: 'white',
  boxShadow: ' 0px 1px 3px rgba(0, 0, 0, 0.1)',
  '.MuiTextField-root': {
    marginBottom: theme.spacing(2)
  },
  'section > .MuiFormLabel-root': {
    fontWeight: 600,
    display: 'block',
    marginBottom: theme.spacing(2),
    color: theme.palette.text.main
  },
  section: {
    marginTop: theme.spacing(4)
  },
  '.footer': {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    strong: {
      fontWeight: 500
    },
    a: {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    }
  },

  '.buttons-group': {
    marginTop: theme.spacing(4),
    button: {
      width: 144
    },
    '& button:first-of-type': {
      background: theme.palette.primary.light,
      color: theme.palette.primary.main,
      marginRight: theme.spacing(2)
    }
  },
  '.uploaded-logo': {
    maxWidth: '100%'
  }
  // '.app-upload-icon': {
  //   '&:hover': {
  //     // background: 'transparent'
  //   }
  // }
}));

const StyledLogoPrev = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  img: {
    width: 120,
    marginRight: theme.spacing(2)
  }
}));
