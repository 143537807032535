import { Table } from 'common/reusables/Table';
import { useNavigate } from 'react-router-dom';
import { formatAppName } from 'app-applications/components/application-list/ApplicationAllList';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getApplications } from 'app-applications/applications.service';

const columns = [
  {
    headerName: 'App Name',
    field: 'appName',
    flex: 0.5,
    renderCell: ({ row }) => {
      return formatAppName(row);
    }
  },
  {
    headerName: 'Description',
    field: 'description',
    valueGetter: ({ value }) => (value ? value : '--'),
    flex: 0.5,
    sortable: false
  },
  {
    headerName: 'Client ID',
    field: 'id',
    valueGetter: ({ value }) => (value ? value : '--'),
    flex: 0.4,
    sortable: false
  },
  {
    headerName: 'Admin',
    field: 'admin',
    valueGetter: ({ value }) => (value ? value : '--'),
    flex: 0.3
  }
];

export const ApplicationPendingList = () => {
  const [pagination, setPagination] = useState({ rowCount: 0, page: 0, pageSize: 10 });

  const navigate = useNavigate();

  const { data: { apps } = { apps: [] }, isFetching } = useQuery(
    [
      'getApplications',
      {
        page: pagination.page + 1,
        q: pagination.search,
        per_page: pagination.pageSize,
        status: 'pending'
      }
    ],
    getApplications,
    {
      onSuccess: ({ paginationMeta }) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          rowCount: paginationMeta.total,
          page: paginationMeta.page,
          pageSize: paginationMeta.pageSize
        }));
      }
    }
  );

  const handleRowClick = (row) => {
    navigate(`/applications/${row.id}`);
  };

  return (
    <Table
      columns={columns}
      rows={apps}
      {...pagination}
      setPagination={setPagination}
      onRowClick={handleRowClick}
      loading={isFetching}
      noRowProps={{
        title: 'No Request..',
        subTitle: 'There seem to be no pending request'
      }}
    />
  );
};
