import { Api, handleApiError } from 'utils/Api';

export const getApplications = async ({ queryKey }) => {
  try {
    const [, params] = queryKey;
    const { data } = await Api.get('/clients', { params });
    return deserializers.getApplicationsData(data);
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createApplication = async (payload) => {
  try {
    const { data } = await Api.post('/clients', serializers.createApplication(payload));
    return deserializers.createApplication(data.data.client);
  } catch (error) {
    throw handleApiError(error);
  }
};

export const updateApplication = async ({ payload, applicationId }) => {
  try {
    const { data } = await Api.put(
      `/clients/${applicationId}`,
      serializers.updateApplication(payload)
    );

    if (payload.app.logoUrl && typeof payload.app.logoUrl === 'object') {
      await uploadAppLogo({ logo: payload.app.logoUrl, appId: applicationId });
    }
    return deserializers.generic(data);
  } catch (error) {
    throw handleApiError(error);
  }
};

export const updateApplicationStatus = async ({ id, payload }) => {
  try {
    await Api.put(`/clients/${id}`, serializers.updateApplicationStatus(payload));
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getApplicationDetails = async (applicationId) => {
  try {
    const { data } = await Api.get(`/clients/${applicationId}`);
    return deserializers.getApplicationDetails(data.data);
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getApplicationUsers = async ({ queryKey }) => {
  try {
    const [, applicationId, params] = queryKey;
    const { data } = await Api.get(`/clients/${applicationId}/users`, { params });
    return deserializers.getApplicationUsers(data);
  } catch (error) {
    throw handleApiError(error);
  }
};

export const uploadAppLogo = async ({ logo, appId }) => {
  try {
    const { data } = await Api.post(
      `/clients/${appId}/logo`,
      serializers.uploadAppLogo(logo)
    );
    return deserializers.generic(data);
  } catch (error) {
    throw handleApiError(error);
  }
};

export const uploadPBSDoc = async ({ doc, appId }) => {
  try {
    const { data } = await Api.post(
      `/clients/${appId}/pse-doc`,
      serializers.uploadPBSDoc(doc)
    );
    return deserializers.generic(data);
  } catch (error) {
    throw handleApiError(error);
  }
};

const serializers = {
  createApplication(payload) {
    return {
      name: payload.name,
      redirect: payload.callbackURL,
      description: payload.description,
      institution_id: payload.institution?.id
    };
  },
  updateApplication(payload) {
    return {
      name: payload.app.name,
      description: payload.app.description,
      redirect: payload.app.callbackURL
    };
  },
  updateApplicationStatus(payload) {
    return {
      status: payload.status,
      meta: {
        rejection_reason: payload.rejectionReason
      }
    };
  },
  uploadAppLogo(logo) {
    const formData = new FormData();
    formData.append('logo', logo);
    return formData;
  },
  uploadPBSDoc(doc) {
    const formData = new FormData();
    formData.append('pse_doc', doc);
    return formData;
  }
};

const deserializers = {
  generic(payload) {
    return {
      message: payload.message,
      data: payload.data
    };
  },
  createApplication(data) {
    return {
      id: data.id
    };
  },

  getApplicationsData(data) {
    return {
      paginationMeta: {
        page: data.current_page - 1,
        pageSize: data.per_page,
        total: data.total,
        from: data.from,
        to: data.to
      },
      apps:
        data?.data?.map((client) => {
          return {
            id: client.id,
            userId: client.user_id,
            description: client.description,
            logo: client.logo,
            name: client.name,
            provider: client.provider,
            redirect: client.redirect,
            personalAccessClient: client.personal_access_client,
            passwordClient: client.password_client,
            noOfUsers: client.tokens_count,
            status: client.status,
            revoked: client.revoked,
            admin: `${client.user?.first_name} ${client.user?.last_name}`,
            createdAt: client.created_at,
            updatedAt: client.updated_at
          };
        }) ?? []
    };
  },

  getApplicationDetails(data) {
    return {
      id: data.client.id,
      userId: data.client.user_id,
      name: data.client.name,
      description: data.client.description,
      usersCount: data.client.tokens_count,
      activeUsersCount: data.client.active_tokens_count,
      inactiveUsersCount: data.client.tokens_count - data.client.active_tokens_count,
      status: data.client?.status,
      logo: data.client.logo,
      adminName: `${data.client.user?.first_name || ''} ${
        data.client.user?.last_name || ''
      }`,
      adminEmail: data.client.user?.email,
      callbackURL: data.client.redirect,
      clientToken: data.client.secret,
      pseDocument: data.client.pse_doc,
      rejectionReason: data.client.meta?.rejection_reason,
      user: {
        firstName: data.client.user.first_name,
        lastName: data.client.user.last_name,
        email: data.client.user.email
      }
    };
  },

  getApplicationUsers(data) {
    return {
      paginationMeta: {
        page: data.current_page - 1,
        pageSize: data.per_page,
        total: data.total,
        from: data.from,
        to: data.to
      },
      users:
        data.data.map((user) => ({
          id: user.id,
          email: user.email,
          status: user.status || 'active',
          lastActivity: user.last_activity || null,
          firstName: user.first_name,
          lastName: user.last_name
        })) || []
    };
  },

  uploadAppLogo(payload) {
    return {
      message: payload.message,
      data: payload.data
    };
  }
};
