import { StyledPageBreadcrumbs } from './PageBreadcrumbs.styles';
import { Link as MuiLink } from 'common/reusables/Link';
import { Link } from 'react-router-dom';
import { Typography } from 'common/reusables/Typography';

export const PageBreadcrumbs = ({ currentPage, history }) => {
  return (
    <StyledPageBreadcrumbs>
      {history.map((stack) => (
        <MuiLink component={Link} to={stack.to || '/'} underline="none" key={stack.title}>
          {stack.title}
        </MuiLink>
      ))}
      <Typography className="currentPage">{currentPage}</Typography>
    </StyledPageBreadcrumbs>
  );
};

PageBreadcrumbs.defaultProps = {
  history: []
};
