import { styled } from '@mui/material';
import { DataGrid } from 'common/reusables/DataGrid';

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius: 0,
  border: 0,
  minHeight: '550px',

  '& .searchField': {
    backgroundColor: theme.palette.common.white
  },

  '& .MuiDataGrid-main': {
    border: `1px solid ${theme.palette.outline.main}`,
    marginTop: '16px',
    backgroundColor: theme.palette.common.white
  },

  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid ${theme.palette.outline.main}`,
    borderRadius: 0
  },

  '& .MuiDataGrid-columnHeader': {
    color: theme.palette.text.light,

    '&Title': {
      fontWeight: 400
    },

    '&:focus-within': {
      outline: 'none'
    },

    '&:first-of-type': {
      paddingLeft: 30
    }
  },

  '&  .MuiDataGrid-columnSeparator': {
    display: 'none'
  },

  '& .MuiDataGrid-row': {
    '& .MuiDataGrid-cell': {
      borderBottom: `1px solid ${theme.palette.outline.main}`,
      color: theme.palette.text.main,
      fontSize: '1rem',
      cursor: 'default',

      '&:focus': {
        outline: 'none'
      },

      '&:first-of-type': {
        paddingLeft: 30
      }
    }
  },

  '& .MuiDataGrid-footerContainer': {
    border: `1px solid ${theme.palette.outline.main}`,
    borderTop: 0,
    minHeight: '40px',
    maxHeight: '40px',
    backgroundColor: theme.palette.common.white,

    '& .MuiToolbar-root, & .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows':
      {
        fontSize: '.75rem',
        color: theme.palette.text.main
      },

    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
      marginTop: 0,
      marginBottom: 0
    },

    '& .MuiTablePagination-actions': {
      marginRight: '15px',

      '& .MuiButtonBase-root': {
        padding: '4px 8px'
      },

      '& .MuiSvgIcon-root': {
        fontSize: '18px'
      }
    }
  }
}));
