import { useState } from 'react';
import BimsLogo from 'assets/images/bims-logo.png';
import { ReactComponent as NotificationIcon } from 'assets/svgs/notification.svg';
import { SvgIcon } from 'common/reusables/SvgIcon';
import { AvatarBadge } from 'common/reusables/AvatarBadge/AvatarBadge';
import { Container } from 'common/reusables/Container';
import { styled } from '@mui/material';
import { AppBar } from 'common/reusables/AppBar';
import { Menu } from 'common/reusables/Menu';
import { MenuItem } from 'common/reusables/MenuItem';
import { IconButton } from 'common/reusables/Button';
import { logOut, getUser } from 'utils/auth';

export const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const user = getUser();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledNavBar position="sticky">
      <Container>
        <img src={BimsLogo} alt="logo" width="66.6px" />
        {/*<Logo className="logo" />*/}
        <div className="navBar-actions">
          <SvgIcon className="notification-icon" component={NotificationIcon} />
          <IconButton onClick={handleOpenMenu}>
            <AvatarBadge
              imgUrl={user.photo}
              fullName={`${user?.first_name} ${user?.last_name}`}
            />
          </IconButton>
        </div>
        <StyledMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem>My Profile</MenuItem>
          <MenuItem onClick={logOut}>Logout</MenuItem>
        </StyledMenu>
      </Container>
    </StyledNavBar>
  );
};

const StyledNavBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',

  '& .MuiContainer-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 16,
    paddingBottom: 16,
    columnGap: 16,
    rowGap: 16
  },

  '& .logo': {
    width: 66,
    height: 32
  },

  '& .navBar-actions': {
    display: 'flex',
    alignItems: 'center',
    columnGap: 25,

    '& .MuiButtonBase-root': {
      padding: 0
    }
  }
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: '190px',
    borderRadius: 0,
    boxShadow: '0px 3px 16px rgba(0, 0, 0, 0.1)'
  },

  '& .MuiMenuItem-root': {
    color: theme.palette.text.main,
    margin: 'auto 8px',
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.outline.main}`
    },

    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.text.dark
    }
  }
}));
