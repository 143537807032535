import { styled } from '@mui/material';
import { TextField } from 'common/reusables/form-components/TextField';
import { pxToRem } from 'utils/pxToRem';

export const StyledSearchField = styled(TextField)(({ theme }) => ({
  width: '100%',

  '& .MuiInputBase-root': {
    borderRadius: 0,

    '& .MuiInputBase-input': {
      padding: '10px 15px 10px 5px',
      fontSize: pxToRem(14)
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.outline.main
    },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: theme.palette.outline.main
    }
  }
}));
