import { Api, handleApiError } from 'utils/Api';

export const getDashboardData = async () => {
  try {
    const { data } = await Api.get('/dashboard');
    return deserializers.getDashboardData(data.data);
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getClients = async ({ queryKey }) => {
  try {
    const [, params] = queryKey;
    const { data } = await Api.get('/clients', { params });
    return deserializers.getClients(data);
  } catch (error) {
    throw handleApiError(error);
  }
};

export const revokeClientAccess = async ({ clientId }) => {
  try {
    await Api.delete(`/clients/${clientId}`);
  } catch (error) {
    throw handleApiError(error);
  }
};

const deserializers = {
  getDashboardData(data) {
    return {
      totalApps: data.clients_count
    };
  },

  getClients(data) {
    return {
      paginationMeta: {
        page: data.current_page - 1,
        pageSize: data.per_page,
        total: data.total,
        from: data.from,
        to: data.to
      },
      clients:
        data?.data?.map((client) => {
          return {
            id: client.id,
            userId: client.user_id,
            description: client.description,
            logo: client.logo,
            name: client.name,
            provider: client.provider,
            redirect: client.redirect,
            personalAccessClient: client.personal_access_client,
            passwordClient: client.password_client,
            status: client.status,
            revoked: client.revoked,
            lastActivity: client.last_login_at,
            createdAt: client.created_at,
            updatedAt: client.updated_at
          };
        }) ?? []
    };
  }
};
