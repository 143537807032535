import { NavBar } from 'common/reusables/NavBar/NavBar';
import { Container } from 'common/reusables/Container';

export const DashboardLayout = ({ children }) => {
  return (
    <>
      <NavBar />
      <Container>{children}</Container>
    </>
  );
};
