import { FormControl } from 'common/reusables/form-components/FormControl';
import { Select } from 'common/reusables/form-components/Select';
import { MenuItem } from 'common/reusables/MenuItem';
import { useState } from 'react';
import { styled } from '@mui/material';
import { pxToRem } from 'utils/pxToRem';

export const SelectFilterField = ({ label, menuItems, disabled, onChange }) => {
  const [value, setValue] = useState('');

  const handleSelectChange = (event) => {
    //TODO: Refactor
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <StyledFormControl variant="filled" disabled={disabled}>
      <Select
        id="filter"
        label={label}
        value={value}
        onChange={handleSelectChange}
        displayEmpty
      >
        <MenuItem value="">{value ? 'None' : `${label}`}</MenuItem>
        {menuItems.map((item) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: '102px',
  backgroundColor: theme.palette.primary[100],

  '& .MuiInputBase-root': {
    borderRadius: 0,

    '&::before, &::after, &:hover:not(.Mui-disabled):before': {
      border: 0
    },

    '&:hover .MuiFilledInput-notchedOutline': {
      border: 'none'
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },

  '& .MuiInputLabel-root': {
    fontSize: pxToRem(16),
    marginTop: -6
  },

  '& .MuiSelect-select': {
    padding: '9px 16px',
    color: '#004722',
    fontWeight: 500
  }
}));
