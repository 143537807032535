import { TableSearchBar } from './TableSearchBar';
import { StyledDataGrid } from './Table.styles';
import { NoRowsOverlay } from 'common/reusables/Table/NoRowsOverlay';

export const Table = ({ columns, rows = [], noRowProps, toolbarProps, ...restProps }) => {
  const handlePageChange = (page) => {
    const { setPagination } = restProps;
    if (!setPagination) return;
    setPagination((prevPagination) => ({
      ...prevPagination,
      page
    }));
  };

  const handlePageSizeChange = (pageSize) => {
    const { setPagination } = restProps;
    if (!setPagination) return;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize
    }));
  };

  const handleTableSearch = (search) => {
    const { setPagination } = restProps;
    if (!setPagination) return;
    setPagination((prevPagination) => ({
      ...prevPagination,
      search
    }));
  };

  return (
    <StyledDataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick
      columns={columns}
      rows={rows}
      onPageSizeChange={handlePageSizeChange}
      onPageChange={handlePageChange}
      rowsPerPageOptions={[10, 20, 30]}
      headerHeight={53}
      hideFooter={rows.length <= 0}
      components={{
        Toolbar: TableSearchBar,
        NoRowsOverlay: NoRowsOverlay
      }}
      componentsProps={{
        noRowsOverlay: {
          ...noRowProps
        },
        toolbar: {
          ...toolbarProps,
          onSearch: handleTableSearch
        }
      }}
      {...restProps}
      paginationMode="server"
    />
  );
};
