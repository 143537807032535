import { formatAppName } from 'app-applications/components/application-list/ApplicationAllList';
import { distanceToNow } from 'utils/date';
import { styled } from '@mui/material';
import { LoadingButton } from 'common/reusables/Button';
import { Table } from 'common/reusables/Table';
import { useState } from 'react';
import { RevokeAccessDialog } from 'app-user/components/RevokeAccessDialog';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getClients, revokeClientAccess } from 'app-user/user.service';
import { toast } from 'react-hot-toast';

const RevokeAccess = ({ row }) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    () => revokeClientAccess({ clientId: row.id }),
    {
      onError: (error) => {
        toast.error(error);
      }
    }
  );

  const handleClose = () => {
    queryClient.invalidateQueries('getClients');
    setOpen(false);
  };

  return (
    <>
      <StyledButton
        inverse="color"
        onClick={() => setOpen(true)}
        loadingPosition="end"
        endIcon={<></>}
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
      >
        Revoke Access
      </StyledButton>
      {open && <RevokeAccessDialog open={open} onClose={handleClose} mutate={mutate} />}
    </>
  );
};

const columns = [
  {
    headerName: 'App Name',
    field: 'name',
    flex: 0.8,
    renderCell: ({ row }) => {
      return formatAppName(row);
    }
  },
  {
    headerName: 'Last Activity',
    field: 'lastActivity',
    valueGetter: ({ value }) => (value ? distanceToNow(new Date(value)) : '--'),
    flex: 0.5
  },
  {
    headerName: 'Action',
    field: 'action',
    flex: 0.2,
    renderCell: ({ row }) => {
      return <RevokeAccess row={row} />;
    },
    sortable: false
  }
];

export const DashboardApplicationList = () => {
  const [pagination, setPagination] = useState({ rowCount: 0, page: 0, pageSize: 10 });

  const { data: { clients } = { clients: [] }, isFetching } = useQuery(
    [
      'getClients',
      {
        page: pagination.page + 1,
        per_page: pagination.pageSize,
        q: pagination.search
      }
    ],
    getClients,
    {
      onSuccess: ({ paginationMeta }) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          rowCount: paginationMeta.total,
          page: paginationMeta.page,
          pageSize: paginationMeta.pageSize
        }));
      }
    }
  );

  return (
    <StyledTable
      columns={columns}
      rows={clients}
      {...pagination}
      setPagination={setPagination}
      noRowProps={{ title: 'No Apps yet' }}
      loading={isFetching}
    />
  );
};

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  padding: '8px 16px',
  fontWeight: 500,

  '&.MuiButton-containedPrimary:hover': {
    backgroundColor: theme.palette.primary.lighter,
    filter: 'brightness(0.97)'
  }
}));

const StyledTable = styled(Table)({
  '& .MuiDataGrid-row .MuiDataGrid-cell': {
    borderBottom: 'none',
    '&:focus-within': {
      outline: 'none'
    }
  }
});
