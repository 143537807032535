export const formatDistanceLocale = {
  lessThanXSeconds: {
    one: 'less than a second',
    other: 'less than {{count}} sec'
  },

  xSeconds: {
    one: '1 sec',
    other: '{{count}} sec'
  },

  halfAMinute: 'half a min',

  lessThanXMinutes: {
    one: 'less than a min',
    other: 'less than {{count}} min'
  },

  xMinutes: {
    one: '1 min',
    other: '{{count}} min'
  },

  aboutXHours: {
    one: 'about 1 hr',
    other: 'about {{count}} hrs'
  },

  xHours: {
    one: '1 hr',
    other: '{{count}} hrs'
  },

  xDays: {
    one: '1 day',
    other: '{{count}} days'
  },

  aboutXWeeks: {
    one: 'about 1 wk',
    other: 'about {{count}} wks'
  },

  xWeeks: {
    one: '1 wk',
    other: '{{count}} wks'
  },

  aboutXMonths: {
    one: 'about 1 mth',
    other: 'about {{count}} mths'
  },

  xMonths: {
    one: '1 mth',
    other: '{{count}} mths'
  },

  aboutXYears: {
    one: 'about 1 yr',
    other: 'about {{count}} yrs'
  },

  xYears: {
    one: '1 yr',
    other: '{{count}} yrs'
  },

  overXYears: {
    one: 'over 1 yr',
    other: 'over {{count}} yrs'
  },

  almostXYears: {
    one: 'almost 1 yr',
    other: 'almost {{count}} yrs'
  }
};
