import { PageBreadcrumbs } from 'common/reusables/PageBreadcrumbs/PageBreadcrumbs';
import { styled } from '@mui/material';
import { pxToRem } from 'utils/pxToRem';

export const ApplicationListNavigation = ({ page, backPages, renderNavAction }) => {
  return (
    <StyledNav className="sticky-nav">
      <PageBreadcrumbs currentPage={page} history={backPages} />
      {renderNavAction && renderNavAction()}
    </StyledNav>
  );
};

const StyledNav = styled('nav')(({ theme }) => ({
  paddingTop: 24,
  paddingBottom: 24,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.background.dashboard,
  position: 'sticky',
  top: 64,
  zIndex: 1,

  '& .MuiButton-root.nav-btn': {
    fontSize: pxToRem(16),
    padding: '6px 16px',
    flexBasis: '168px',
    fontWeight: 500
  }
}));
