import {
  StyledTableTab,
  StyledTableTabs
} from 'app-admin/components/table-tab/TableTabs.styles';
import { ReactComponent as AllIcon } from 'assets/svgs/all.svg';
import { ReactComponent as PendingIcon } from 'assets/svgs/pending.svg';
import { SvgIcon } from 'common/reusables/SvgIcon';
import { useQuery } from 'react-query';
import { getApplications } from 'app-applications/applications.service';

export const TableTabs = ({ value, setValue }) => {
  const { data = {} } = useQuery(
    ['getPendingRequestCount', { client: 'pending' }],
    getApplications
  );

  return (
    <StyledTableTabs value={value} onChange={setValue}>
      <StyledTableTab
        icon={<SvgIcon component={AllIcon} viewBox="0 0 16 17" />}
        iconPosition="start"
        label="Apps"
        value="all"
      />
      <StyledTableTab
        icon={<SvgIcon component={PendingIcon} viewBox="0 0 16 17" />}
        iconPosition="start"
        label={
          <>
            Requests{' '}
            <span className="request-count">({data?.paginationMeta?.total || 0})</span>
          </>
        }
        value="pending"
      />
    </StyledTableTabs>
  );
};
