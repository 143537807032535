import { Avatar } from 'common/reusables/Avatar';
import { CellChip } from 'common/reusables/Table/CellChip';
import { Button } from 'common/reusables/Button';
import { styled } from '@mui/material';
import { pxToRem } from 'utils/pxToRem';

const statusMapping = {
  approved: 'success',
  rejected: 'error'
};

export const AppDetailsHeader = ({ name, status, imgUrl, onViewInformation }) => {
  return (
    <StyledAppDetailsHeader>
      <div className="appName">
        <h1 className="appName-title">
          <Avatar src={imgUrl} /> <span>{name}</span>
        </h1>
        <CellChip value={status} type={statusMapping[status?.toLowerCase()]} />
      </div>
      <Button variant="text" className="appLink" onClick={onViewInformation}>
        View App Information
      </Button>
    </StyledAppDetailsHeader>
  );
};

const StyledAppDetailsHeader = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginBottom: 18,

  '& .appName': {
    display: 'flex',
    alignItems: 'center',
    columnGap: 16
  },

  '& .appName-title': {
    display: 'flex',
    alignItems: 'center',
    fontSize: pxToRem(24),
    margin: 0,

    '& .MuiAvatar-root': {
      marginRight: 11,
      width: 26,
      height: 26
    }
  },

  '& .appLink': {
    padding: 8,

    '&:hover': {
      backgroundColor: 'unset'
    }
  }
}));
