import { Table } from 'common/reusables/Table';
import { distanceToNow } from 'utils/date';
import { CellChip } from 'common/reusables/Table/CellChip';
import { SelectFilterField } from 'common/reusables/Table/SelectFilterField';

const statusMapping = {
  active: 'success',
  inactive: 'error'
};

const getFullName = ({ firstName, lastName }) => {
  return `${firstName || ''} ${lastName || ''}`;
};

const columns = [
  {
    headerName: 'Name',
    field: 'name',
    valueGetter: ({ row }) => getFullName(row),
    flex: 0.8
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 0.7,
    sortable: false
  },
  {
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => {
      return <CellChip value={value} type={statusMapping[value?.toLowerCase()]} />;
    },
    flex: 0.4
  },
  {
    headerName: 'Last Activity',
    field: 'lastActivity',
    valueGetter: ({ value }) => (value ? distanceToNow(value) : '--'),
    flex: 0.3
  }
];

export const AppUsersList = ({ list, pagination, setPagination, loading }) => {
  const handleFilter = (value) => {
    setPagination((prevState) => ({
      ...prevState,
      status: value
    }));
  };

  return (
    <Table
      columns={columns}
      rows={list}
      {...pagination}
      setPagination={setPagination}
      loading={loading}
      noRowProps={{
        title: 'No Users yet',
        subTitle: 'No User has linked up applications'
      }}
      toolbarProps={{
        renderFilter: () => (
          <SelectFilterField
            label="Status"
            menuItems={[
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'Inactive' }
            ]}
            disabled={list?.length <= 0}
            onChange={handleFilter}
          />
        )
      }}
    />
  );
};
