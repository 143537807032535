import { TextField, PasswordField } from 'common/reusables/form-components/TextField';
import { LoadingButton } from 'common/reusables/Button';
import { useMutation } from 'react-query';
import { registerUser } from 'app-auth/auth.service';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';

const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: ''
};

const validationSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  email: Yup.string().email('Invalid Email').required('Required'),
  password: Yup.string().required('Required')
});

export const PersonalInformationForm = ({ onProceed }) => {
  const mutation = useMutation(registerUser, {
    onSuccess(data) {
      toast.success('Success');
      window.localStorage.setItem('token', data.token);
      window.localStorage.setItem('user', JSON.stringify(data.user));
      onProceed();
    },
    onError(error) {
      toast.error(error);
    }
  });

  const {
    handleChange,
    values: formValues,
    handleBlur,
    handleSubmit,
    errors,
    touched
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: mutation.mutate,
    validateOnBlur: true
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  const getInputProps = (name) => {
    return {
      value: formValues[name],
      error: !!getError(name),
      helperText: getError(name),
      onChange: handleChange,
      onBlur: handleBlur
    };
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        name="firstName"
        label="First Name"
        {...getInputProps('firstName')}
      />
      <TextField
        fullWidth
        sx={{ mt: 2 }}
        name="lastName"
        label="Last Name"
        {...getInputProps('lastName')}
      />
      <TextField
        fullWidth
        sx={{ mt: 2 }}
        name="phone"
        label="Phone Number"
        {...getInputProps('phone')}
      />
      <TextField
        fullWidth
        sx={{ mt: 2 }}
        type="email"
        name="email"
        label="Email Address"
        {...getInputProps('email')}
      />
      <PasswordField
        fullWidth
        sx={{ mt: 2 }}
        type="password"
        name="password"
        label="Password"
        {...getInputProps('password')}
      />
      {/* <TextField fullWidth sx={{ mt: 2 }} name="nin" label="NIN" />
      <TextField fullWidth sx={{ mt: 2 }} name="bvn" label="BVN" /> */}

      <LoadingButton
        fullWidth
        variant="contained"
        type="submit"
        sx={{ mt: 4 }}
        loading={mutation.isLoading}
      >
        Proceed
      </LoadingButton>
    </form>
  );
};
