import { Avatar } from 'common/reusables/Avatar';
import { getNameInitials } from 'utils/getAvatarNameInitials';
import { StyledAvatarBadge } from './AvatarBadge.style';

export const AvatarBadge = ({ imgUrl, fullName }) => {
  return (
    <StyledAvatarBadge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
    >
      <Avatar alt={fullName} src={imgUrl ? imgUrl : getNameInitials(fullName)}>
        {fullName && getNameInitials(fullName)}
      </Avatar>
    </StyledAvatarBadge>
  );
};
