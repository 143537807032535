import { TextField } from 'common/reusables/form-components/TextField';
import { LoadingButton } from 'common/reusables/Button';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { createApplication } from 'app-applications/applications.service';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { TagInput } from 'common/reusables/TagsInput';
import { getInstitutions } from 'app-admin/admin.service';
import { Autocomplete } from '@mui/material';

const initialValues = {
  name: '',
  description: '',
  institution: '',
  callbackURL: ''
};

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  callbackURL: Yup.string().required('Required')
});

export const AppDetailsForm = ({ onProceed }) => {
  const mutation = useMutation(createApplication, {
    onSuccess(data) {
      toast.success('Saved!');
      window.localStorage.setItem('app_id', data.id);
      onProceed();
    },
    onError(error) {
      toast.error(error);
    }
  });

  const { data: institutions = [] } = useQuery(['getInstitutions'], getInstitutions);

  const {
    handleChange,
    values: formValues,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: mutation.mutate,
    validateOnBlur: true
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  const getInputProps = (name) => {
    return {
      value: formValues[name],
      error: !!getError(name),
      helperText: getError(name),
      onChange: handleChange,
      onBlur: handleBlur
    };
  };

  return (
    <form onSubmit={handleSubmit}>
      <Autocomplete
        options={institutions}
        getOptionLabel={(option) => option?.name ?? ''}
        value={formValues?.institution}
        onChange={(_, selected) => {
          setFieldValue('institution', selected);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="institution"
            label="Institution"
            onBlur={handleBlur}
          />
        )}
      />

      <TextField
        fullWidth
        name="name"
        label="App Name"
        {...getInputProps('name')}
        sx={{ mt: 2 }}
      />

      <TextField
        fullWidth
        multiline
        sx={{ mt: 2 }}
        minRows={3}
        name="description"
        label="App Description"
        {...getInputProps('description')}
      />

      <TagInput
        sx={{ mt: 2 }}
        value={getInputProps('callbackURL').value}
        label="Redirect URL"
        name="callbackURL"
        error={getInputProps('callbackURL').error}
        helperText={getInputProps('callbackURL').helperText}
        onChange={getInputProps('callbackURL').onChange}
        onBlur={getInputProps('callbackURL').onBlur}
      />

      <LoadingButton
        fullWidth
        variant="contained"
        type="submit"
        sx={{ mt: 4 }}
        loading={mutation.isLoading}
      >
        Proceed
      </LoadingButton>
    </form>
  );
};
