import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as FolderIcon } from 'assets/svgs/folder_icon.svg';
import { LoadingButton, IconButton } from 'common/reusables/Button';
import { uploadAppLogo, uploadPBSDoc } from 'app-applications/applications.service';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { ReactComponent as FileTypeIcon } from 'assets/svgs/fileType.svg';
import { ReactComponent as TrashIcon } from 'assets/svgs/redTrash.svg';

const StyledUploadedFile = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  paddingBottom: theme.spacing(2)
}));

export const UploadBox = ({
  instruction,
  onUpload = () => {},
  onCancel,
  acceptedFiles = '*',
  renderExistingFile,
  existingFile
}) => {
  const [uploadedFile, setUploadedFile] = useState(existingFile);
  const maxFileSize = 5 * 1000;

  const handleDrop = (_, payload) => {
    const file = payload?.[0]?.file;
    setUploadedFile(file);
    onUpload(file);
  };

  const fileSizeValidator = (selectedFile) => {
    if (selectedFile.size > maxFileSize) {
      return {
        code: 'tooLarge',
        message: `File size is larger than ${maxFileSize / 1000000}Mb`
      };
    }

    return null;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: acceptedFiles,
    multiple: true,
    validator: fileSizeValidator
  });

  const handleCancel = () => {
    setUploadedFile(null);
    onCancel();
  };

  if (existingFile && renderExistingFile) {
    return renderExistingFile();
  }

  if (uploadedFile) {
    const fileDateObject = new Date(uploadedFile.lastModifiedDate);
    const fileDate = fileDateObject.toDateString();
    const fileFullTime = fileDateObject.toLocaleTimeString();
    const [timeNumber, timePeriod] = fileFullTime.split(' ');
    const [hour, minute] = timeNumber.split(':');
    const fileTime = `${hour}:${minute} ${timePeriod}`;

    const isKb = uploadedFile.size / (1000 * 1000) < 1;
    const fileSize = isKb
      ? `${(uploadedFile.size / 1000).toFixed(1)} KB`
      : `${(uploadedFile.size / (1000 * 1000)).toFixed(1)} MB`;

    return (
      <StyledUploadedFile container justifyContent="space-between" alignItems="center">
        <Grid item xs={1}>
          <FileTypeIcon />
        </Grid>

        <Grid item xs={9}>
          <Typography fontWeight={500}>{uploadedFile?.name}</Typography>
          <Typography variant="body2">
            {fileDate} at {fileTime} • {fileSize}
          </Typography>
        </Grid>
        <Grid item xs={1} textAlign="right">
          <IconButton onClick={handleCancel}>
            <TrashIcon />
          </IconButton>
        </Grid>
      </StyledUploadedFile>
    );
  }

  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <StyledDropzone {...getRootProps()}>
        <input {...getInputProps()} />

        <Box
          height={150}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <FolderIcon />
          <Typography mt={2} fontWeight="600">
            Drop your files here or
            <Box mx={0.6} component="span" color="primary.main">
              click here
            </Box>
            to upload
          </Typography>
          <Typography variant="body2">{instruction}</Typography>
        </Box>
      </StyledDropzone>
    </Box>
  );
};

export const AppLogoUpload = ({ onProceed }) => {
  const [logo, setLogo] = useState(null);
  const [pbs, setPbs] = useState(null);

  const logoMutation = useMutation(uploadAppLogo, {
    onSuccess(data) {
      toast.success(data.message);
    },
    onError(error) {
      toast.error(error);
    }
  });

  const pbsMutation = useMutation(uploadPBSDoc, {
    onSuccess(data) {
      toast.success(data.message);
    },
    onError(error) {
      toast.error(error);
    }
  });

  const onSubmit = async () => {
    const appId = window.localStorage.getItem('app_id');
    await logoMutation.mutateAsync({ appId, logo });
    await pbsMutation.mutateAsync({ appId, doc: pbs });

    onProceed();
  };

  return (
    <div>
      <Typography fontWeight={600} mb={1}>
        App Logo
      </Typography>
      <UploadBox
        instruction="Format (png). 24px by 24px. Maximum of 5KB"
        onUpload={setLogo}
        onCancel={() => setLogo(null)}
      />

      <Typography fontWeight={600} mb={1} mt={5}>
        Evidence of PSE Approval
      </Typography>
      <UploadBox
        instruction="Format (.pdf, .doc). Maximum of 5MB"
        onUpload={setPbs}
        onCancel={() => setPbs(null)}
      />

      <Typography mt={7} mb={3}>
        By clicking “Submit”, You agree to the{' '}
        <Link to="/terms-and-conditions">Terms and Conditions</Link> of using this
        platform for the purpose which it serves.
      </Typography>

      <LoadingButton
        fullWidth
        variant="contained"
        onClick={onSubmit}
        disabled={!logo || !pbs}
        loading={logoMutation.isLoading || pbsMutation.isLoading}
      >
        Submit
      </LoadingButton>
    </div>
  );
};

const StyledDropzone = styled('div')(({ theme }) => ({
  outline: 'none',
  border: `1px dashed ${theme.palette.grey[300]}`,
  padding: theme.spacing(1),
  '&:hover, &:focus': {
    opacity: 0.72,
    cursor: 'pointer'
  }
}));
