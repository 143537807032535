import axios from 'axios';
import { getToken, isAuthenticated } from 'utils/auth';

export const base_url = process.env.REACT_APP_API_BASE_URL;

export const parseComplexQueryParams = (queryParams) => {
  const params = new URLSearchParams();
  Object.keys(queryParams).forEach((key) => {
    if (queryParams[key] instanceof Array) {
      queryParams[key].forEach((item) => {
        params.append(`${key}[]`, item);
      });
    } else {
      params.set(key, queryParams[key]);
    }
  });
  return params;
};

const Api = axios.create({
  baseURL: base_url
});

Api.interceptors.request.use(
  (config) => {
    // This adds an authorization key to config object if a token exists.
    if (isAuthenticated()) {
      config.headers.common['Authorization'] = `Bearer ${getToken()}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// This ensures that appropriate error message from the API are parsed to react query
Api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || error)
);

export { Api };

export const handleApiError = (error) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line
    console.warn('[Axios Error]', error, error.response);
  }

  if (error.message) return error.message;

  if (!error.response) return error.message || 'Something went wrong';

  const { response } = error;
  const { data } = response;

  switch (response.status) {
    case 400:
      return (
        (data.error && JSON.stringify(data.error)) ||
        data.message ||
        'Something went wrong'
      );
    case 404:
      return data.message || 'Resource not found';
    case 409:
      return 'A duplicate already eists';
    default:
      return 'Something went wrong';
  }
};
