export const Button = (theme) => ({
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      disableTouchRipple: true,
      disableElevation: true
    },
    styleOverrides: {
      root: {
        fontWeight: 400,
        borderRadius: '0',
        boxShadow: 'none',
        textTransform: 'none',
        paddingTop: '16px',
        paddingBottom: '16px',
        '&:hover': {
          boxShadow: 'none'
        }
      }
    },
    variants: [
      {
        props: { color: 'primary', inverse: 'color' },
        style: {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main
        }
      },
      {
        props: { color: 'error', inverse: 'color' },
        style: {
          backgroundColor: theme.palette.error[100],
          color: theme.palette.error.light
        }
      }
    ]
  }
});
