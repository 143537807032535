import { CircularProgress, styled } from '@mui/material';

export const Spinner = (props) => {
  return <StyledCircularProgress {...props} />;
};

const StyledCircularProgress = styled(CircularProgress)(() => ({
  margin: 'auto',
  display: 'block'
}));
