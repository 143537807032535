import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { updateApplicationStatus } from 'app-applications/applications.service';
import { toast } from 'react-hot-toast';
import { Button, LoadingButton } from 'common/reusables/Button';
import { ApplicationRejectionDrawer } from 'app-applications/components/application-details/ApplicationRejectionDrawer';
import { ApplicationDialog } from 'app-applications/components/application-details/ApplicationDialog';
import successGif from 'assets/gifs/registration-success.gif';

export const AdminDrawerAction = ({ closeDetailsDrawer, id, status }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);

  const queryClient = useQueryClient();

  const handleCloseDrawer = () => {
    closeDetailsDrawer();
  };

  const handleCloseApproveModal = () => {
    closeDetailsDrawer();
    queryClient.invalidateQueries('getApplicationDetails');
  };

  const { mutate, isLoading } = useMutation(
    (payload) => updateApplicationStatus({ id, payload }),
    {
      onSuccess: () => {
        setOpenApproveModal(true);
      },
      onError: (error) => {
        toast.error(error);
      }
    }
  );

  const handleApprove = () => {
    const payload = { status: 'approved' };
    mutate(payload);
  };

  return (
    <>
      {['rejected', 'approved'].includes(status) ? (
        <Button onClick={handleCloseDrawer}>Close</Button>
      ) : (
        <>
          <Button
            color="error"
            inverse="color"
            className="inverseBtn"
            onClick={() => setOpenDrawer(true)}
          >
            Reject
          </Button>
          <LoadingButton
            onClick={handleApprove}
            loadingPosition="end"
            endIcon={<></>}
            variant="contained"
            loading={isLoading}
            disabled={isLoading}
          >
            Approve
          </LoadingButton>
          <ApplicationRejectionDrawer
            open={openDrawer}
            closeDrawer={handleCloseDrawer}
            id={id}
          />
          <ApplicationDialog
            open={openApproveModal}
            onClose={handleCloseApproveModal}
            dialogImage={successGif}
            title="Request Approved"
            subtitle="Request has been approved"
            buttonLabel="Close"
          />
        </>
      )}
    </>
  );
};
