import {
  Divider,
  Drawer as MuiDrawer,
  Grid,
  IconButton,
  styled,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { pxToRem } from 'utils/pxToRem';

export const Drawer = ({ title, open, anchor = 'right', onClose, children }) => {
  return (
    <MuiDrawer anchor={anchor} open={open} onClose={onClose}>
      <StyledContent>
        <Grid container className="title" alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton onClick={onClose} disableRipple>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        {children}
      </StyledContent>
    </MuiDrawer>
  );
};

const StyledContent = styled('div')(({ theme }) => ({
  width: 552,
  maxWidth: 552,
  '.dialog-content': {
    maxWidth: 552,
    background: 'red'
  },
  '.title': {
    padding: theme.spacing(3),
    '.MuiIconButton-root': {
      height: 11,
      padding: 0
    }
  },
  '.MuiTypography': {
    '&-h4': {
      fontSize: pxToRem(16),
      lineHeight: '0px',
      color: theme.palette.text.light,
      fontWeight: 600
    }
  }
}));
