import { TextField } from 'common/reusables/form-components/TextField';
import { Button, LoadingButton } from 'common/reusables/Button';
import { AuthPageLayout } from 'app-auth/components/auth-page-layout/AuthPageLayout';
import { useState } from 'react';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { pxToRem } from 'utils/pxToRem';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { loginAdmin } from 'app-auth/auth.service';
import qs from 'qs';

const redirectWhitelist = ['https://bims.tetfund.gov.ng', 'http://localhost'];

const initialFormValues = {
  email: '',
  password: ''
};

const validationSchema = Yup.object({
  email: Yup.string().email('Enter a valid email address.').required('Required'),
  password: Yup.string().required('Required')
});

export const AdminSignInRoute = () => {
  const { continue: redirect } = qs.parse(window.location.search?.replace('?', ''));

  const [currentInput, setCurrentInput] = useState('email'); // 'email | password'

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(loginAdmin);

  const onSubmit = () => {
    mutate(formValues, {
      onSuccess(data) {
        window.localStorage.setItem('token', data.token);
        window.localStorage.setItem('user', JSON.stringify(data.user));

        if (redirect && !!redirectWhitelist.find((v) => redirect.startsWith(v))) {
          window.location.href = `${redirect}?token=${data.token}`;
          return;
        }
        navigate('/');
      },
      onError(error) {
        toast.error(error);
      }
    });
  };

  const {
    handleChange,
    values: formValues,
    handleBlur,
    handleSubmit,
    errors,
    touched
  } = useFormik({
    initialValues: initialFormValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  const formDescription = currentInput === 'email' ? 'Enter Email' : 'Enter Password';

  const handleProceed = () => {
    setCurrentInput('password');
  };

  return (
    <AuthPageLayout title="Sign In" description={formDescription}>
      <StyledForm onSubmit={handleSubmit}>
        {currentInput === 'email' ? (
          <>
            <TextField
              fullWidth
              name="email"
              type="email"
              label="Email Address"
              value={formValues.email}
              error={!!getError('email')}
              helperText={getError('email')}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Button
              fullWidth
              variant="contained"
              onClick={handleProceed}
              disabled={!formValues.email || !!errors.email}
            >
              Proceed
            </Button>
          </>
        ) : (
          <>
            <TextField
              fullWidth
              name="password"
              type="password"
              label="Password"
              value={formValues.password}
              error={!!getError('password')}
              helperText={getError('password')}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <LoadingButton
              fullWidth
              variant="contained"
              type="submit"
              loading={isLoading}
            >
              Sign In
            </LoadingButton>
          </>
        )}
      </StyledForm>
    </AuthPageLayout>
  );
};

const StyledForm = styled('form')(({ theme }) => ({
  button: {
    borderRadius: 0,
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    textTransform: 'capitalize',
    fontSize: pxToRem(16)
  }
}));
