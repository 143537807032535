export const Alert = (theme) => ({
  MuiAlert: {
    styleOverrides: {
      filledError: {
        backgroundColor: '#FEEFEF',
        color: theme.palette.text.main,
        fontWeight: 400
      },
      filledWarning: {
        backgroundColor: '#FFF3D8',
        color: theme.palette.text.main,
        fontWeight: 400
      }
    }
  }
});
