import { useState } from 'react';
import { ApplicationPendingList } from 'app-applications/components/application-list/ApplicationPendingList';
import { ApplicationAllList } from 'app-applications/components/application-list/ApplicationAllList';
import { TableTabs } from 'app-admin/components/table-tab/TableTabs';

const TabPanel = ({ children, tabValue, label }) => {
  return <div hidden={tabValue !== label}>{tabValue === label && children}</div>;
};

export const ApplicationList = () => {
  const [value, setValue] = useState('all');

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TableTabs value={value} setValue={handleTabChange} />
      <TabPanel tabValue={value} label="all">
        <ApplicationAllList />
      </TabPanel>
      <TabPanel tabValue={value} label="pending">
        <ApplicationPendingList />
      </TabPanel>
    </>
  );
};
