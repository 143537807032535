import { SvgIcon } from 'common/reusables/SvgIcon';
import { styled } from '@mui/material';
import { ReactComponent as EmptyData } from 'assets/svgs/emptyData.svg';
import { Typography } from 'common/reusables/Typography';
import { pxToRem } from 'utils/pxToRem';

export const NoRowsOverlay = ({ title, subTitle }) => {
  return (
    <StyledNoRowsOverlay>
      <SvgIcon component={EmptyData} viewBox="0 0 116 116" />
      <Typography component="h2" variant="h2" className="title">
        {title}
      </Typography>
      <Typography component="h2" variant="subtitle1" className="subTitle">
        {subTitle}
      </Typography>
    </StyledNoRowsOverlay>
  );
};

const StyledNoRowsOverlay = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '& .MuiSvgIcon-root': {
    fontSize: pxToRem(115),
    color: 'transparent',
    marginBottom: 40
  },

  '& .title': {
    fontSize: pxToRem(18),
    color: '#323130',
    marginBottom: '4px'
  },

  '& .subTitle': {
    fontSize: pxToRem(14),
    color: '#605E5C'
  }
});
