import {
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider
} from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { themeOptions } from './themeOptions';

import { ComponentOverrides } from './overrides';

export const ThemeProvider = ({ children }) => {
  const theme = createTheme(themeOptions);
  theme.components = ComponentOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
