import { formatDistanceToNowStrict, format } from 'date-fns';
import locale from 'date-fns/locale/en-US';

import { formatDistanceLocale } from 'utils/dateLocale';

const formatDistance = (token, count, options) => {
  options = options || {};
  let result;

  const tokenValue = formatDistanceLocale[token];

  if (typeof tokenValue === 'string') {
    result = tokenValue;
  } else if (count === 1) {
    result = tokenValue.one;
  } else {
    result = tokenValue.other.replace('{{count}}', count.toString());
  }

  if (options?.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      return 'in ' + result;
    } else {
      return result + ' ago';
    }
  }
};

export const distanceToNow = (date) => {
  return formatDistanceToNowStrict(date, {
    addSuffix: true,
    locale: {
      ...locale,
      formatDistance
    }
  });
};

export const formatDate = (date, dateFormat) => {
  return format(date, dateFormat);
};
