import { useState } from 'react';
import { AlertBanner } from 'common/reusables/AlertBanner/AlertBanner';
import { Button } from 'common/reusables/Button';
import { Dialog, DialogContent, DialogTitle } from 'common/reusables/Dialog';
import { TextField } from 'common/reusables/form-components/TextField';
import { Close } from '@mui/icons-material';
import { IconButton } from 'common/reusables/Button';
import { styled } from '@mui/material';
import { pxToRem } from 'utils/pxToRem';
import { useNavigate } from 'react-router-dom';

export const ApplicationStatusAlert = ({ showAlert, setShowAlert, rejectionReason }) => {
  const [openRejectionModal, setOpenRejectionModal] = useState(false);
  const navigate = useNavigate();

  const handleCloseRejectionModal = () => {
    setOpenRejectionModal(false);
  };

  return (
    <>
      {showAlert.pending && (
        <AlertBanner
          variant="filled"
          severity="warning"
          onClose={() => setShowAlert((prevState) => ({ ...prevState, pending: false }))}
          content={
            <span>
              Your account is pending approval from the BIMS Admin. Kindly exercise
              patience while we review your application.
            </span>
          }
        />
      )}
      {showAlert.rejected && (
        <AlertBanner
          variant="filled"
          severity="error"
          onClose={() => {}}
          content={
            <>
              <span>
                Application not approved.{' '}
                <span className="cta" onClick={() => setOpenRejectionModal(true)}>
                  Click here to review reasons for rejection
                </span>
              </span>
              <Button onClick={() => navigate('edit')}>Edit Application Details</Button>
            </>
          }
        />
      )}
      <StyledDialog open={openRejectionModal} onClose={handleCloseRejectionModal}>
        <DialogTitle>
          <span>Reason for Rejection</span>
          <IconButton onClick={handleCloseRejectionModal}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            multiline
            fullWidth
            rows={7}
            placeholder="Type here"
            inputProps={{
              readOnly: true
            }}
            value={rejectionReason}
          />

          <Button
            inverse="color"
            onClick={handleCloseRejectionModal}
            className="dialogContent__btn"
          >
            Close
          </Button>
        </DialogContent>
      </StyledDialog>
    </>
  );
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '500px',
    width: '70%'
  },

  '& .MuiDialogTitle-root': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0px -1px 0px #E5E5EA',

    '& span': {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.main
    },

    '& .MuiIconButton-root': {
      color: '#242533'
    },

    '& .MuiIconButton-root .MuiSvgIcon-root': {
      fontSize: '20px'
    }
  },

  '& .MuiDialogContent-root': {
    marginTop: '24px',

    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      marginBottom: '24px',
      color: theme.palette.text.light,

      '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
        {
          borderColor: theme.palette.outline[100],
          borderWidth: '1px'
        }
    },

    '& .MuiButton-root': {
      display: 'flex',
      marginLeft: 'auto',
      padding: '0.65em 2.5em',
      fontSize: pxToRem(16),
      fontWeight: 500,
      width: '100%',
      maxWidth: '155px',

      '&.MuiButton-containedPrimary:hover': {
        backgroundColor: theme.palette.primary.lighter,
        filter: 'brightness(0.97)'
      }
    }
  }
}));
