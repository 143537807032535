import { ApplicationList } from 'app-applications/components/application-list/ApplicationList';
import { StatCards } from 'app-admin/components/stat-cards/StatCards';
import { ApplicationListNavigation } from 'app-applications/components/application-list/ApplicationListNavigation';
import { ReactComponent as TotalIcon } from 'assets/svgs/school.svg';
import { ReactComponent as TotalUsersIcon } from 'assets/svgs/uni.svg';
import { ReactComponent as TotalUsersOnline } from 'assets/svgs/student.svg';
import { DashboardLayout } from 'app-admin/components/dashboard-layout/DashboardLayout';
import { Button } from 'common/reusables/Button';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getDashboardData } from 'app-admin/admin.service';
import { Spinner } from 'common/reusables/Spinner';

export const DashboardPage = () => {
  const navigate = useNavigate();

  const { data: dashboardData, isFetching: isFetchingDashboardData } = useQuery(
    ['getDashboardData'],
    getDashboardData
  );

  const handleAddApp = () => {
    navigate('/application');
  };

  const cards = [
    { title: 'Total App', count: dashboardData?.stats?.totalApps, icon: TotalIcon },
    {
      title: 'Total Users',
      count: dashboardData?.stats?.totalUsers,
      icon: TotalUsersIcon
    },
    {
      title: 'Total Users Online',
      count: dashboardData?.stats?.totalOnlineUsers,
      icon: TotalUsersOnline
    }
  ];

  if (isFetchingDashboardData)
    return (
      <DashboardLayout>
        <Spinner sx={{ mt: 20 }} />
      </DashboardLayout>
    );
  return (
    <DashboardLayout>
      <StyledAppContainer>
        <ApplicationListNavigation
          page="home"
          renderNavAction={() => (
            <Button className="nav-btn" onClick={handleAddApp}>
              Add App
            </Button>
          )}
        />
        <StatCards cards={cards} />
        <ApplicationList />
      </StyledAppContainer>
    </DashboardLayout>
  );
};

export const StyledAppContainer = styled('div')({
  position: 'relative',
  paddingBottom: 10
});
