const primary = {
  main: '#006B33',
  100: '#CCE1D6',
  200: '#6BB700',
  300: '#0A8043',
  light: '#CCE1D6',
  lighter: '#AACEBB'
};

const secondary = {
  main: '#0050C8'
};

const text = {
  main: '#393A4A',
  dark: '#272833',
  light: '#6B6C7E',
  light2: '#707070',
  black: '#000000'
};

const error = {
  main: '#FF0000',
  100: '#FEEFEF',
  light: '#DA1414'
};

const outline = {
  main: '#E5E5EA',
  100: '#CDCED9'
};

const background = {
  dashboard: '#F8FBFA'
};

export const palette = {
  primary,
  secondary,
  text,
  outline,
  error,
  background
};
