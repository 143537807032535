import { GlobalStyles } from 'common/theme/overrides/GlobalStyles';
import { Button } from 'common/theme/overrides/Button';
import { Alert } from 'common/theme/overrides/Alert';

export const ComponentOverrides = (theme) => {
  return {
    ...GlobalStyles(theme),
    ...Button(theme),
    ...Alert(theme)
  };
};
