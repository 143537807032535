import { Dialog, DialogContent } from 'common/reusables/Dialog';
import { Typography } from 'common/reusables/Typography';
import { Button } from 'common/reusables/Button';
import { styled } from '@mui/material';
import { pxToRem } from 'utils/pxToRem';

export const ApplicationDialog = ({
  open,
  onClose,
  title,
  subtitle,
  buttonLabel,
  dialogImage
}) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContent>
        <img src={dialogImage} alt="alert gif" className="dialogContent__img" />
        <Typography component="h1" variant="h5" className="dialogContent__title">
          {title}
        </Typography>
        <Typography variant="subtitle1" className="dialogContent__subtitle">
          {subtitle}
        </Typography>
        <Button inverse="color" onClick={onClose} className="dialogContent__btn">
          {buttonLabel}
        </Button>
      </DialogContent>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '414px',
    width: '70%',
    padding: '40px 48px',
    borderRadius: 0
  },

  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,

    '& .dialogContent': {
      '&__img': {
        maxWidth: '104px',
        height: 'auto',
        marginBottom: '16px'
      },
      '&__title': {
        fontSize: pxToRem(23),
        fontWeight: 500,
        marginBottom: 4,
        color: theme.palette.text.main
      },

      '&__subtitle': {
        fontSize: pxToRem(16),
        color: theme.palette.text.light,
        marginBottom: '20px'
      },

      '&__btn': {
        padding: '0.65em 2.5em',
        fontSize: pxToRem(16),
        fontWeight: 500,
        width: '100%',
        maxWidth: '155px',

        '&.MuiButton-containedPrimary:hover': {
          backgroundColor: theme.palette.primary.lighter,
          filter: 'brightness(0.97)'
        }
      }
    }
  }
}));
