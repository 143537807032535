import { styled } from '@mui/material';
import { Breadcrumbs } from 'common/reusables/Breadcrumbs';
import { pxToRem } from 'utils/pxToRem';

export const StyledPageBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  '& .MuiBreadcrumbs-separator': {
    fontSize: pxToRem(12),
    color: theme.palette.text.light
  },

  '& .MuiBreadcrumbs-li': {
    '& .MuiLink-root': {
      color: theme.palette.text.light,
      textTransform: 'capitalize',
      fontSize: pxToRem(14),
      cursor: 'pointer'
    }
  },

  '& .currentPage': {
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
    color: theme.palette.text.main,
    fontWeight: 500
  }
}));
